@import "@scss/common/variables";
@import "@scss/common/extends";
//complete task
.compelte-task-wrapper {
  position: fixed;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
  top: 10px;
  height: 100%;
  max-width: calc(100% - 350px);
  width: 100%;
  right: 1px;
  background-color: $white-color;
  z-index: 20;
  transform: translateX(120%);
  transition: all 300ms ease-in-out;
  border-left: 1px solid $theme-border-color;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 1000;

}

.compelte-task-wrapper-add {
  position: fixed;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
  top: 10px;
  height: 100%;
  max-width: calc(40%);
  width: 100%;
  right: 10px;
  background-color: $white-color;
  z-index: 20;
  transform: translateX(120%);
  transition: all 0.8s ease-in-out;
  border-left: 1px solid $theme-border-color;
  max-height: 100vh;
  overflow-y: auto;
}

.compelte-task-wrapper--active {
  transform: translateX(0);
}
.compelte-task-wrapper__header {
  padding: 12px;
  height: 60px;
  background-color: #1a73e8;
}
.compelte-task-wrapper__title {
  color: #ffffff;
  font-size: 18px;
}

.compelte-task-wrapper__completion {
  padding: 24px;
  border-right: 1px solid $theme-border-color;
}
.compelte-task-wrapper__response {
  padding: 24px;
  border-right: 1px solid $theme-border-color;
}
.compelte-task-wrapper__form-group {
  margin: 16px 0;
}
.compelte-task-wrapper__status {
  max-width: 364px;
  width: 100%;
  border-radius: $base-border-radius;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  color: $theme-primary-color;
  border: 1px solid $theme-border-color;
  background-color: #f7f7f7;
  &--width {
    max-width: 100%;
    color: $theme-font-primary-color;
  }
}
.compelte-task-wrapper__label {
  margin-bottom: 8px;
  font-weight: $medium-font-weight;
  color: $theme-font-heading-color;
}
.compelte-task-wrapper__label_popup {
  font-weight: $medium-font-weight;
  color: $theme-font-heading-color;
}
.compelte-task-wrapper__common-title {
  color: $theme-primary-color;
  font-weight: $medium-font-weight;
}

.compelte-task-wrapper__text-area {
  padding: 10px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  &::placeholder {
    color: $theme-border-color;
  }
}
.custom-file {
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  padding: 24px;
  min-width: 690px;
  width: 100%;
}
.custom-file--attach {
  background-color: #f7f7f7;
  padding: 0 16px;
  height: 32px;
  line-height: 32px;
  border-radius: $base-border-radius;
  cursor: pointer;
}
.compelte-task-wrapper__uploaded {
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  background-color: #f7f7f7;
  border-radius: $base-border-radius;
  span {
    font-size: 13px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.unassociated_main_container {
  overflow: auto;
  max-height: calc(100vh - 140px);
  height: calc(100vh - 140px);
  .unassociate_list_container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    .image_container {
      margin-right: 15px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        @extend %transition;
        // border: 0.5px solid #1a73e8;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .image_container_body {
        width: 200px;
        height: 150px;
        border: 1px solid #dadcde;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .image_containertext_body {
        padding: 5px;
        width: 200px;
        min-height: 40px;
        border: 1px solid #dadcde !important;
        border-top: none !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .small_text {
          color: #5f6368;
          font-size: 12px;
        }
      }
      .input_checkbox {
        position: absolute;
        height: 16px;
        width: 16px;
        cursor: pointer;
        top: 10px;
        left: 10px;
      }
    }
  }
}

.comment_history_modal_wrapper  {
  max-width: 650px;
  height: auto;
  max-height: max-content;

  .compelte-task-wrapper__header {
    padding: 14px 24px;
    background-color: #276EF1;
    height: auto;

    .compelte-task-wrapper__title {
      font-size: 21px;
      line-height: 18px;
      font-weight: $medium-font-weight;
    }

    .close-modal-btn {
      height: 36px;
      width: 36px;
      padding: 6px;
      border-radius: 50%;
      background-color: #2874FA;

      &:hover {
        background-color: rgba(40, 116, 250, 0.5 );
      }
    }
  }

  .approver_flow_main_container {
    padding: 24px;
    max-height: calc(100vh - 64px - 10px);

    .approver_flow_wrap {
      margin-bottom: 24px;
      position: relative;

      &:after {
        content: "";
        // height: calc(100% - 32px);
        height: calc(100% - 16px);
        width: 1px;
        background-color: $theme-border-color;
        position: absolute;
        // left: 24px;
        // top: 48px;
        left: 20px;
        top: 40px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .profile_circle {
        // width: 48px;
        // height: 48px;
        // min-width: 48px;
        // min-height: 48px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        margin-right: 16px;

        .title {
          font-size: 20px;
          line-height: 18px;
          font-weight: $light-font-weight;
        }
      }

      .approver_name {
        font-size: 16px;
        line-height: 18px;
        color: #202124;
        font-weight: $medium-font-weight;
        // margin-bottom: 4px;
      }

      .approver_time {
        font-size: 12px;
        line-height: 18px;
        color: $theme-font-primary-color;
        margin-bottom: 8px;
      }

      .approver_desc {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: $theme-font-primary-color;
      }
    }
  }
}