@import "@scss/common/variables";
@import "@scss/common/extends";

.effects {
  background-color: rgba(var(--primary-color), 0.06);
  background-color: #fdf5f7;
  // background-color: #f2f3ff;
  color: rgba(var(--primary-color), 1);
  // color: #408dfb;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: rgba(var(--primary-color), 1);
    // background-color: #408dfb;
  }
}

.expense-create-wrapper {
  .expense-create-wrapper__header {
    position: sticky;
    border-bottom: 1px solid $theme-border-color;
    padding: 0 24px 0;
    height: 50px;
    top: 0;
    // margin-top: 6px;
    background-color: white;
    .expense-create-wrapper__header-back-link {
      max-width: 24px;
      width: 100%;
    }
    .expense-create-wrapper__add-user-info {
      text-align: center;
      color: var(--primary-color);
      position: relative;
      margin-left: 20px;

      &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--primary-color);
      }
    }
    .expense-create-wrapper__cta-btn {
      height: 40px;
      line-height: 40px;
      margin: 4px 0;
      border-radius: 4px;
      border: none;
      background-color: #ffffff;

      &--primary {
        color: rgba(var(--button-primary), 1);
        background-color: #ffffff;
        margin-right: 8px;
        border: 1px solid $theme-border-color;
        padding: 0 40px;
      }
      &--secondary {
        background-color: rgba(var(--button-primary), 1);
        color: #ffffff;
        padding: 0 24px;
      }
    }
  }
  .expense-create-wrapper__form-wrapper {
    max-width: 744px;
    width: 100%;
    margin: 20px auto;
    .expense-create-wrapper__form-roles
      .form-group
      .expense-create-wrapper__form-control {
      width: 100%;
    }
    .expense-create-wrapper__form-batch-wrapper {
      width: 100%;
      border: 1px solid $theme-border-color;
      padding: 8px;
      border-radius: 4px;
      gap: 8px;
      button.badge {
        color: var(--primary-color);
        background-color: #fff5ea;
        border-radius: 18px;
        border: none;
        font-weight: 400;
        height: 34px;
        line-height: 34px;
        padding: 0 16px;
        font-size: 14px;
        img.close-btn {
          margin-left: 8px;
        }
      }
    }
  }
  .expense-create-wrapper__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    &--grid-gap-vendor-profile {
      gap: 0 24px;
    }
    &--org-profile {
      gap: 0 24px;
    }
  }
  .expense-create-wrapper__form-group {
    min-width: 360px;
    width: 100%;
    margin-bottom: 0;
  }

  .expense-create-wrapper_container {
    display: flex;
    // height: calc(100vh - 70px - 50px - 51px); //with footer
    // overflow: auto;

    .expense-create-wrapper_left {
      flex-basis: 55%;
      height: calc(100vh - 70px - 51px);
      overflow: auto;
      border-right: 1px solid #00000029;
      .expense-create-wrapper_left_container {
        margin: 3%;
        .expense-create-wrapper_left_container_header {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 15px;
        }
      }
      .report-detail-wrapper_report_documentsection {
        min-height: 320px;
        margin: 3%;

        .image_container {
          margin-right: 10px;
          .image_container_body {
            height: 184px;
            width: 206px;
            border: 1px solid #dadce0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          .image_containertext_body {
            height: 52px;
            width: 206px;
            border-top: none !important;
            border: 1px solid #dadce0 !important;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
    .expense-create-wrapper_right {
      flex-basis: 45%;
      height: calc(100vh - 70px - 51px);
      overflow: auto;
      // min-height: 320px;
      // height: 120vh;
    }
  }
}

.header_button_container {
  height: 50px;
  border-bottom: 1px solid #dadce0;
}

.report_submit {
  &__red {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #e74c3c;
    padding: 5px;
    border-radius: 5px;
    h6 {
      color: #e74c3c;
      font-weight: 400;
    }
    &:hover {
      @extend %transition;
      background-color: #e74c3c;
      padding: 5px;
      border-radius: 5px;
      h6 {
        color: white;
      }
    }
  }
  &__green {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid#28b463;
    padding: 5px;
    border-radius: 5px;
    h6 {
      color: #28b463;
      font-weight: 400;
    }
    &:hover {
      @extend %transition;
      background-color: #28b463;
      padding: 5px;
      border-radius: 5px;
      h6 {
        color: white;
      }
    }
  }
}

.document_list_view {
  background-color: #eef4ff;
  border-radius: 5px;
  p {
    font-size: 14px;
    color: #5f6368;
  }
}
.expense_amount_calculation_section {
  margin-top: 24px;
  .bar_section {
    // background-color: #f8f8fd;
    border-top: 1px solid $theme-border-color;
    // border-radius: 5px;
    // height: 50px;
    padding: 16px 0;
    margin-top: 24px;
  }

  .expense_amount_currency {
    margin-left: 124px;
  }
}

.approver_flow_container {
  border: 1px solid #efefef;
  border-left: 3px solid #408dfb;
  min-height: 60px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.status-poistion-absolute {
  position: absolute;
  right: 2px;
  top: 2px;
}
.approver_flow_main_container {
  overflow: auto;
  max-height: calc(100vh - 60px);
}

.expense-create-wrapper__cta-btns {
  .ascent-button--primary {
    margin-right: 16px;
  }
}

.expense-add-custom-inputfile {
  .custom-inputfile {
    margin-top: 5px;
    img {
      margin-bottom: 8.56px;
    }

    .custom-inputfile__text {
      font-size: $font-size-12;
      height: auto;
      line-height: $line-height-16;
      margin-bottom: 4px;
    }

    a {
      font-size: $font-size-12;
      height: auto;
      line-height: $line-height-16;
      margin-bottom: 4px;
      display: inline-block;
      font-weight: $medium-font-weight;
    }
  }
}


.create-report-popup-wrapper {
  .compelte-task-wrapper__header {
    padding: 12px 24px;
  }

  .fixed_moda_footer_wrapper {
    z-index: 8;
  }

  .unassociated_main_container {
    padding: 24px;

    .form_wrapper-pipeline {
      width: 100%;
      margin: 0 0 16px 0;

      &:last-child {
        margin-bottom: 0;
      }

      .form_builder_field {
        .form-label {
          margin-left: 0;
          font-size: $font-size-14;
          line-height: $line-height-18;
          font-weight: $medium-font-weight;
          margin-bottom: 8px;
          color: $theme-font-primary-color;
        }
        .form-label-error {
          margin-left: 0;
          font-size: $font-size-14;
          line-height: $line-height-18;
          font-weight: $medium-font-weight;
          margin-bottom: 8px;
        }

        .error-text {
          margin-top: 0;

          .srv-validation-message {
            margin-top: 8px;
          }
        }

        .form-control {
          height: 44px;
          padding: 12px 16px;
          font-size: $font-size-16;
          line-height: $line-height-18;
        }

        .custom-form-control-date {
          height: 44px;
          padding: 12px 16px;
          font-size: $font-size-16;
          line-height: $line-height-18;
        }

        .selected-value-box {
          height: 44px;
          padding: 12px 16px;
          font-size: $font-size-16;
          line-height: $line-height-18;

          .search_container {
            .search_input {
              margin-left: 10px;
              font-size: $font-size-16;
              line-height: $line-height-18;
            }
          }

          .right_container {
            right: 8px;
            top: 10px;

            .arrow_image_container {
              .arrow_image {
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
}