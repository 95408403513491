@import "@scss/common/variables";

.approval_table_section {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  table {
    width: 100%;
    thead {
      //   border-top: 1px solid #dadce0;

      tr {
        td {
          padding: 18px 10px;
        }
      }
    }
    tbody {
      border-top: 1px solid #dadce0;

      tr {
        border-bottom: 1px solid #dadce0;
        cursor: pointer;
        td {
          padding: 18px 10px;
        }
        &:hover {
          background-color: #f7faff;
        }
      }
    }
  }
}

.approval-preference__container {
  width: 80%;
  margin: 0 auto;
  overflow: auto;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  &__section {
    margin-top: 20px;
    padding: 18px 10px;
    width: 350px;
    border: 1px solid #dadce0;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    &.active {
      background-color: #f3f3fa;
    }
  }
  &__radio {
    width: 20%;
    height: 18px;
    margin-top: 5px;
  }
}

.custom_approval__container{
  width:100%;
  padding-left: 4%;
  padding-right: 4%;

  overflow: auto;
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
}


.approval__container{
  &__radio {
    // width: 20%;
    transform: scale(1.5); 
  }
}


.small_button{
  &__green{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #22b378;
    background-color: #22b378;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      color: white;
    }
  }
}

.header_section {
  width: 300px;
  &__150{
    width: 150px;
  }
  &__200{
    width: 200px;
  }
}


.custom_approval__container {
  padding-top: 24.5px;
  padding-bottom: 24.5px;

  .approval-label-field-wrap {
    .approval-label-wrap {
      font-size: $font-size-14;
      line-height: $line-height-18;
      margin-bottom: 8px;
      color: $theme-font-primary-color;
      font-weight: $medium-font-weight;

      &.form-label-error {
        color: $validation-font-color;
      }
    }

    input {
      &.form-control {
        padding: 13px 16px;
        height: 44px;
        font-size: $font-size-14;
        line-height: $line-height-18;
      }
    }

    textarea {
      padding: 13px 16px; 
      font-size: $font-size-14;
      line-height: $line-height-18;
    }
  }

  .approval-detail-border-bottom {
    margin: 24.5px 0 24px;
  }

  .custom-approval-header-wrap {
    margin-bottom: 16.5px;

    h6 {
      font-size: $font-size-20;
      line-height: $line-height-25;
      font-weight: $medium-font-weight;
      color: $theme-font-heading-color;
      margin-bottom: 1px;
    }

    .small_text {
      font-size: $font-size-12;
      line-height: $line-height-18;
      color: $theme-font-primary-color;
    }
  }
  .selected-value-box {
    &.disabled {
      background-color: #F7F7F7;

      .search_input {
        background-color: #F7F7F7 !important;
      }
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .custom-approval-step2 { 
    .custom-approval-criteria-wrrapper {
      // .custom-approval-criteria-first-field {
      //   .selected-value-box {
      //     max-width: 170px;

      //     .search_input {
      //       min-width: auto !important;
      //     }
      //   }
      // }
      .selected-value-box {
        height: 44px;
        padding: 13px 16px;
        margin-right: 16px;
        max-width: 230px;

        .search_input {
          font-size: $font-size-14;
          line-height: $line-height-18;
          color: $theme-font-heading-color;
          min-width: auto;
        }
        
        .right_container {
          top: 7px;
          right: 8px;
        }
      }
    }
  }

  .custom-approval-step3 {
    .custom-approval-header-wrap {
      margin-bottom: 19.5px;
    }

    .custom-approval-step3-input-radio-wrap {
      margin-right: 18px;
      .approval__container__radio {
        width: 20px;
        height: 20px;
        display: inline-block;
        transform: none;
        margin-right: 10px;
        accent-color: #276EF1;
      }

      p {
        font-size: $font-size-14;
        line-height: $line-height-18;
        font-weight: $medium-font-weight;
      }
    }

    .custom-approval-approvals-wrrapper {
      .selected-value-box {
        height: 44px;
        padding: 13px 16px;
        margin-right: 16px;
        max-width: 300px;
        width: 300px !important;

        .search_input {
          font-size: $font-size-14;
          line-height: $line-height-18;
          color: $theme-font-heading-color;
          min-width: auto;
        }
        
        .right_container {
          top: 7px;
          right: 8px;
        }
      }
    }
  }

  .custom_field_button_container {
    .custom_field_button_container_image {
      height: 24px;
      width: 24px;
    }

    .custom_field_button_container_text {
      padding-left: 8px;
    }
  }
}