@import "@scss/common/_variables.scss"; 

.travel_document_container {

  display: flex;
  .travel_document_container_item_left {
    overflow-y: auto;
    max-height: calc(100vh - 140px);
    height: calc(100vh - 140px);
    flex-grow: 1;
    width: 40%;
    // margin: 4%;
    padding: 20px 20px 10px 20px;
  }
  .travel_document_container_item_right {
    overflow-y: auto;
    max-height: calc(100vh - 140px);
    height: calc(100vh - 140px);
    flex-grow: 1;
    width: 50%;
    // margin: 4%;
    padding: 20px 20px 10px 20px;

  }
}

.primary_image{
  position: absolute;
  right: 20px;
  top: 2px;
}

.travel_document_container {
  .travel_document_container_item_left {
    .expense-create-wrapper_left_container_header {
      margin-bottom: 8px;
      font-size: $font-size-20;
      line-height: $line-height-25;
      color: $theme-font-heading-color;
      font-weight: $medium-font-weight;
    }
  }

  .travel_document_container_item_right {
    border-left: 1px solid $theme-border-color;

    .user-details-wrapper__form-group {
      .checkpoint-active-details__label {
        font-size: $font-size-14;
        line-height: $line-height-18;
        margin-bottom: 8px;
        margin-left: 0;
        color: $theme-font-primary-color;

        &.form-label-error {
          color: #b94a48 !important;
        }
      }

      .selected-value-box {
        height: 44px;
      }

      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            height: 44px;
          }
        }
      }

      input.form-control {
        height: 44px;
      }
    }

    .react-datepicker-popper {
      z-index: 11 !important;
    }
  }
}