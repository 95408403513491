@import "@scss/common/variables";

.effects {
  background-color: rgba(var(--primary-color), 0.06);
  background-color: #fdf5f7;
  // background-color: #f2f3ff;
  color: rgba(var(--primary-color), 1);
  // color: #408dfb;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: rgba(var(--primary-color), 1);
    // background-color: #408dfb;
  }
}

.expense-detail-wrapper_container {
  display: flex;
  // height: calc(100vh - 70px - 46px);
  height: calc(100vh - 70px - 40px);
  overflow: auto;

  .expense-detail-wrapper_left {
    flex-basis: 33%;
    border-right: 1px solid #00000029;
    overflow: auto;
    max-height: calc(100vh - 116px);
    height: calc(100vh - 116px);

    .expense-detail-wrapper_left_container {
      // margin: 3%;
      margin-top: 1%;

      .expense-detail-wrapper_left_container_header_section {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;
        height: 54px;
        .expense-detail_headerleft_section {
        }
        .expense-detail_headerright_section {
        }
      }
      .expense-detail-wrapper_left_container_list_section {
        overflow: auto;
        max-height: calc(100vh - 70px - 105px);
        height: calc(100vh - 70px - 105px);
        .expense-detail-wrapper_left_container_list_item {
          cursor: pointer;
          height: 110px;
          // border-bottom: 1px solid #efefef;
          margin-top: 5px;
          margin-bottom: 5px;
          padding-left: 15px;
          &.active {
            @extend .effects;
          }
          .expense-detail-wrapper_header_text {
            color: #3c4043;
            font-size: 13px;
            &__dark {
              color: black;
              font-size: 13px;
              font-weight: 500;
            }
          }
          .expense-detail-wrapper_desc_text {
            color: #5f6368;
            font-size: 12px;
          }
          .expense-detail-wrapper_status_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .expense-detail-wrapper_right {
    flex-basis: 67%;
    // background-color: #f8f8fd;
    background-color: white;
    border-right: 1px solid #efefef;
    // height: 120vh;
    .expense-detail-wrapper_right_container_header_section {
      background-color: #fff !important;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;
      min-height: 68px;
      .expense-detail_headerleft_section {
      }
      .expense-detail_headerright_section {
      }
    }
    .expense-detail-wrapper_right_container {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      overflow: auto;
      max-height: calc(100vh - 70px - 115px);
      height: calc(100vh - 70px - 115px);
      margin: 0 auto;
      .expense-detail-wrapper_right_detail_report_section {
        border-bottom: 1px solid #dadce0;

        .expense-detail-wrapper_report {
          height: 73px;
          padding: 10px;
          .header_text {
            color: #276ef1;
            font-size: 16px;
            font-weight: 700;
          }
          .desc_text {
            color: #5f6368;
            font-size: 14px;
            margin-top: 6px;
          }
        }
      }
      .expense-detail-wrapper_report_documentsection {
        // min-height: 320px;
        background-color: #fff !important;
        border: 1px solid #efefef;
        .image_container {
          margin-right: 10px;
          .image_container_body {
            height: 184px;
            width: 206px;
            border: 1px solid #dadce0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          .image_containertext_body {
            height: 52px;
            width: 206px;
            border-top: none !important;
            border: 1px solid #dadce0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .expense-detail-wrapper_report_infosection {
        background-color: #fff;
        border-bottom: 1px dashed #dadce0;
        border-top: 1px dashed #dadce0;
        height: 70px;

        .expense-detail-wrapper_report {
          height: 73px;
          padding: 10px;
          .header_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 700;
          }
          .desc_text {
            color: #5f6368;
            font-size: 12px;
            margin-top: 6px;
          }
        }
      }
      .expense-detail-wrapper_report_detail_headersection {
        height: 51px;
        border-bottom: 1px solid #dadce0;
      }
    }
  }
}

.expense_violation_container {
  background-color: white;
  min-height: 60px;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
}

.expense_amount_section {
  background-color: white;
  min-height: 60px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.expense_detail_container {
  background-color: white;
  // min-height: 60px;
  // border: 1px solid #efefef;
  border-radius: 5px;
  border-top: none;
  margin-bottom: 30px;
}
.detail_border {
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 5px;
  border-bottom: none;
}

.expense-load-form-wrapper {
  min-height: calc(100% - 67px - 90px - 10px);
  // width: 100%;
  // margin: 0 auto;
  // padding: 24px;
  // position: relative;

  width: calc(100% + 60px);
  margin: 0 auto;
  padding: 24px;
  position: relative;
  margin-left: -30px;
  margin-right: -30px;

  .expense-load-form-overlay {
    position: absolute;
    opacity: 0.92;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: #FDFEFF;

  }

  .expense-load-form-content-holder {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    color: #ccc;
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .expense-load-dummy-form {
    position: relative;
    z-index: 1;
    .dummy-label-textbox-wrap {
      margin-bottom: 16px;
    }
  }
}

.mileage_absolute {
  top: 0;
  right: 0;
  height: 40px;
  overflow: hidden;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dadce0;
}

.margin-action-icon {
  margin-right: 8px;
}

.expense-detail-wrapper_container {
  .expense-detail-wrapper_right {
    .expense-detail-wrapper_right_container_header_section {
      height: 72.5px;
      padding: 17px 26px 16.5px;

      .expense-detail_headerleft_section {
        .expense-detail_headertitle {
          line-height: $line-height-20;
          font-size: $font-size-16;
          margin-bottom: 3px;
        }

        .expense-detail_header_created {
          font-size: $font-size-12;
          line-height: $line-height-16;
          color: $theme-font-primary-color;
        }
      }
    }

    .expense-detail-wrapper_right_detail_section {
      .expense-detail-wrapper_right_container {
        padding-left: 26px;
        padding-right: 26px;

        .expense_violation_container {
          margin-top: 24px;
        }

        .expense_amount_section {
          margin: 24px 0;
        }

        .expense-detail-wrapper_report_infosection {
          position: relative;
          border-top: 0;
          border-bottom: 0;

          &:before {
            content: "";
            background-image: linear-gradient(to right, $theme-border-color 50%, rgba(255, 255, 255, 0) 0%);
            background-position: top;
            background-size: 10px 3px;
            background-repeat: repeat-x;
            height: 1px;
            width: 100%;
            position: absolute;
            top: 0;
          }

          &:after {
            content: "";
            background-image: linear-gradient(to right, $theme-border-color 50%, rgba(255, 255, 255, 0) 0%);
            background-position: top;
            background-size: 10px 3px;
            background-repeat: repeat-x;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
          }

          .expense-detail-wrapper_report {
            height: auto;
            padding: 16px 16.5px;
            position: relative;

            &:first-child {
              padding-left: 0;

              &:after {
                  display: none;
                }
            }

            &:last-child {
              padding-right: 0;

              // &:after {
              //   display: none;
              // }
            }

            &:after {
              content: "";
              height: 32px;
              width: 1px;
              background-color: $theme-border-color;
              position: absolute;
              // right: 0;
              left: 0;
            }
            .header_text {
              font-size: $font-size-14;
              line-height: $line-height-18;
              color: $theme-font-heading-color;
              margin-bottom: 3px;
            }

            .desc_text {
              margin-top: 0;
              font-size: $font-size-12;
              line-height: $line-height-18;
              color: $theme-font-primary-color;
            }
          }
        }
        
        .expense_detail_container {
          .expense_detail_container_wrapper {
            margin: 16.5px auto 0;

            .desc_container {
              padding-bottom: 8px;
              .label_text {
                font-size: $font-size-12;
                line-height: $line-height-18;
                color: $theme-font-primary-color;
                margin-bottom: 2px;
              }

              .header_text {
                font-size: $font-size-14;
                line-height: $line-height-18;
                font-weight: $medium-font-weight;
                color:$theme-font-heading-color;
              }
            }
          }
        }
      }
    }
  }

  .expense-detail-wrapper_left_container {
    .expense-detail-wrapper_report_documentsection {
      padding: 26px;
    }
  }
}


// Expense Detail Navigation Wrapper CSS
.expense_details_navigation_wrapper {
  margin-top: 16.5px;
  .inner-page-navigation-with-back-cta-buttons {
    height: 36px;
    padding: 0;
    margin: 0;

    .inner-page-navigation-with-back-cta-buttons__navigation_left_temp {
      bottom: -0.5px;
      top: 0;
      left: 0;

      .inner-page-navigation-with-back-cta-buttons__nav-list-item {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.border-bottom-spacing-expense-detail-wrap {
  margin-top: 16.5px;
  margin-bottom: 16.5px;
}


.expense-create-wrapper_container {
  .expense-create-wrapper_right {
    padding: 23.5px 30px;

    .form_wrapper-pipeline {
      margin: 0 0 16px 0;

      .form_builder_field {
        .form-label {
          margin-left: 0;
          font-size: $font-size-14;
          line-height: $line-height-18;
          font-weight: $medium-font-weight;
          margin-bottom: 8px;
          color: $theme-font-primary-color;
        }

        .form-label-error {
          margin-left: 0;
          font-size: $font-size-14;
          line-height: $line-height-18;
          font-weight: $medium-font-weight;
          margin-bottom: 8px;
        }

        .custom-form-control-textarea {
          height: 83px;
          font-size: $font-size-16;
          line-height: $line-height-18;
        }

        .error-text {
          margin-top: 0;

          .srv-validation-message {
            margin-top: 8px;
          }
        }

        .form-control {
          height: 44px;
          padding: 12px 16px;
          font-size: $font-size-16;
          line-height: $line-height-18;
        }

        .custom-form-control-date {
          height: 44px;
          padding: 12px 16px;
          font-size: $font-size-16;
          line-height: $line-height-18;
        }

        .selected-value-box {
          height: 44px;
          padding: 12px 16px;
          font-size: $font-size-16;
          line-height: $line-height-18;

          .search_container {
            .search_input {
              margin-left: 10px;
              font-size: $font-size-16;
              line-height: $line-height-18;
            }
          }

          .right_container {
            right: 8px;
            top: 10px;

            .arrow_image_container {
              .arrow_image {
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
}

.expense-detail-wrapper_report {
  &.view-more-dd-holder {
    .header_text {
      color: #276EF1;
    }
  }
}

// .modal-backdrop {
//   // &.show {
//     &.readMoreBackDrop {
//       opacity: 1 !important;
//       background-color: transparent;
//     }
//   // }
// }

.readMoreModalReports  {

  .modal-content {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border: 0;
    border-radius: 0;
  }

  .modal-header {
    padding: 21px 16px;
    height: 60.5px;

    .modal-title {
      font-size: $font-size-14;
      line-height: $line-height-18;
      color: $theme-font-heading-color;
    }

    .modal-close {
      width: 24px;
      height: 24px;
      padding: 0;
      margin-right: 0;
    }
  }
  
  .modal-body {
    padding: 0;

    .more-details-body-wrap {
      padding: 16.5px 24px;
      .more-details-label-value {
        margin-bottom: 16px;
        .mb-value-holder {
          font-size: $font-size-16;
          line-height: $line-height-20;
          color: $theme-font-heading-color;
          font-weight: $medium-font-weight;
          margin-bottom: 3px;
        }

        .md-label-holder {
          font-size: $font-size-12;
          line-height: $line-height-16;
          color: $theme-font-primary-color;

        }
      }
    }
  }
  
}


.violationAccordian  {
  background-color: #FFF4EA;
  color: #A85500;
  padding: 8px;
  text-align: center;

  .accordion-item , .accordion-button{
    background-color: #FFF4EA;
    color: #A85500;
  }

  .accordion-button {
    justify-content: center;

    .violation-arrow-icon-holder {
      transition: all 0.3s ease-in-out;
      height: 18px;
      line-height: 18px;
      transform: rotate(180deg);
    }

    &.collapsed {
      .violation-arrow-icon-holder {
        transform: rotate(0deg);
      }
    }
  }

  .color_violation_desc {
    margin-left: 4px;
    font-size: $font-size-12;
    line-height: $line-height-18;
  }
}

.asscociate-wrap {
  border: 1px solid #efefef;
  padding: 10px 15px;
  width: auto;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1607843137);

  .associate-text-content {
    .icon-holder {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      background-color: #F7F7F7;
      min-height: 50px;
      min-width: 50px;
    }
  
    .outer-border-rotated-icon {
      position: relative;
    
      &:before {
        content: "";
        border: 1px solid $theme-border-color;
        width: 22px;
        display: block;
        height: 22px;
        position: absolute;
        border-radius: 4px;
        transform: rotate(45deg);
        right: -3px;
      }
    }
  }

  .dropdown {
    &::before {
      display: none;
    }
  }
  .associate-create-report-btn {
    padding: 4px 8px;
    border-radius: 2px;
  }
}



.dd-reportlist {
  width: 320px;
  inset : 45px auto auto 0px !important;

  .dd-reportlist-holder {
    height: 300px;
    overflow-y: auto;

    .dd-reportname-list-wrap {
      padding: 10px;
      border-bottom: 1px solid $theme-border-color;
      cursor: pointer;

      &:hover {
        background-color: #f7f7f7;
      }

      &:last-child {
        border-bottom: 0;
      }

      .dd-reportname-id {
        margin-bottom: 4px;
        color: #4A5158;
        font-weight: $medium-font-weight;
      }

      .dd-reportname-currency {
        font-weight: bold;
      }

      .dd-reportname-name {
        font-size: 14px;
        font-weight: $medium-font-weight;
        color: #495057;
        margin-bottom: 4px;
      }
      
      .dd-reportname-duration {
        color: #6A6E9A;
        font-size: 12px;
      }
    }
  }
}


.associate-add-report-button {
  color: #69A4FC !important;

  &:hover {
    background-color: transparent;
  }
}

.expense_type_comment {
  background-color: rgb(232, 240, 254);
  font-size: 12px;
  color: rgb(32, 72, 145);
  padding: 8px;
  margin-bottom: 8px;
}
.expense-create-wrapper_right {
  .react-datepicker__input-container input.form-control {
    border: 1px solid $theme-border-color !important;
  }
}

.date-time-wrap {
  .react-datepicker-wrapper {
      min-width: 173.5px;
    input.form-control  {
      min-width: 173.5px !important;
    }
    // &:first-child {
    //   input.form-control {
    //     border-top-right-radius: 0;
    //     border-bottom-right-radius: 0;
    //     border-right: 0;
    //   }
    // }

    // &:last-child {
    //   input.form-control {
    //     border-top-left-radius: 0;
    //     border-bottom-left-radius: 0;
    //   }
    // }
  }

  &.only-time-wrap {
    .react-datepicker-wrapper {
      width: 100%;

      // input {
      //   border-radius: 0.375rem !important;
      //   border-top-right-radius: 0.375rem;
      //   border-bottom-right-radius: 0.375rem;
      // }
    }
  }
}

 .expense-detail-wrapper_report_infosection-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: auto !important;

    &.expense-detail-wrapper_report_infosection  {
      .expense-detail-wrapper_report:first-child {
        padding-left: 16.5px !important;
      }

      .expense-detail-wrapper_report {
        position: relative;
        padding: 10px 0 10px 16.5px !important;

        &:before {
          content: "";
          background-image: linear-gradient(to right, #dadce0 50%, rgba(255, 255, 255, 0) 0%);
          background-position: top;
          background-size: 10px 3px;
          background-repeat: repeat-x;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
 }

 .per-diem-expens-rate-list .datatable_table .datatable_table_tbody .datatable_table_tr .datatable_table_td:nth-child(1) {
  text-align: left !important;
 }

 .per-diem-expens-rate-list {
  .datatable_table .datatable_table_thead .datatable_table_th, .datatable_table .datatable_table_tbody .datatable_table_tr .datatable_table_td {
    padding-top:10px ;
    padding-bottom: 10px;
    height: auto !important;
  }
 }

 .proof-upload-wrapper {
  .expense-add-custom-inputfile {
    height: auto !important;
    min-width: auto !important;
    > div {
      height: auto !important;
      padding: 0 !important;
      min-width: 100px !important;
      max-width: 100px !important;
      
      .custom-inputfile {
        min-width: auto !important;
        padding: 0 !important;
        img {
          display: none;
        }
      }
    }
  }
 }

 .auto-scan-proof-wrap {
  .proof-upload-wrapper .expense-add-custom-inputfile > div .custom-inputfile {
    height: 29px;
    line-height: 29px;

    .custom-inputfile__text {
      padding: 0;
      color: #333333;
    }
  }
 }

 .proof-carousel {
  .btn-caret {
    border: 1px solid $theme-border-color;
    padding: 0;

    &:hover {
      background-color: #dadada;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &.btn-caret-left {
      transform: rotate(90deg);
    }

    &.btn-caret-right {
      transform: rotate(-90deg);
    }
  }
  .proof-item {
    // .image_container_document {
    //   .image_container_body {
    //     height: auto !important;
    //     width: 50px !important;
    //   }

    //   .image_containertext_body {
    //     width: 50px !important;
    //     padding: 0 !important;
    //     .header_tex {
    //       display: none;
    //     }
    //   }
    // }

    .image_containertext_body {
      border-radius: 5px;
      width: auto !important;
    }
  }
 }


 .document-proof-pair {
  width: 260px;

  .document-section {
    .image_container_document {
      margin-right: 0;

      .image_container_body, .image_containertext_body {
        width: 100% !important;
      }
    }
  }
}