@import "@scss/common/variables";

.organization_container {
  overflow: auto;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
}
.checkpoint-active-details-wrapper__form-content {
  max-width: 744px;
  margin: 0 auto;
  width: 100%;
}
.checkpoint-active-details-wrapper__form-content_new {
  max-width: 844px;
  // margin: 0 auto;
  width: 100%;
  margin-left: 100px;
}
.checkpoint-active-details-wrapper__form-content_email_template {
  max-width: 650px;
  margin: 30px;
  width: 100%;
}
.checkpoint-active-wrapper {
  position: relative;
  top: 65px;
  width: 100%;
}

.nav-link-image {
  margin: 24px 24px;
}

.user-details-wrapper__header--new-header {
  position: sticky;
  top: 65px;
  background-color: #fff;
  z-index: 1;
}

.checkpoint-active-details-wrapper__form-wrapper {
  padding-top: 24px;
}

.css-1s2u09g-control {
  border-color: $theme-border-color !important;
  min-height: 40px;
  min-width: 364px;
}

.css-14el2xx-placeholder {
  color: #dadce0 !important;
}

.user-details-wrapper__form-group label.checkpoint-active-details__label {
  font-weight: 500;
  color: #5f6368 !important;
  font-size: 12px;
  gap: 24px;
}

.org-profile-wrapper {
  max-width: 744px;
  width: 100%;
  // margin: 24px auto 0;
  margin: 32.5px auto 0;
}
.org-profile-wrapper-image {
  flex: 1;
  transition: 0.3ms all ease-in-out;
  // border: 1px dashed rgba(0, 0, 0, 0.08);
  border: 1px solid $theme-border-color;
  border-radius: 4px;
  transition: all 0, 5s ease-in-out;
  &:hover {
    box-shadow: 4px 4px 10px #00000029;
    border-color: rgba(var(--secondary-color), 1);
  }
}
.org-profile-wrapper-image img {
  object-fit: contain;
}
.org-profile-wrapper__details {
  margin-left: 24px;
}
.org-profile-wrapper__details-heading {
  color: $theme-font-heading-color;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: $line-height-18;
}
.org-profile-wrapper__details-text {
  color: #5f6368;
  font-size: 14px;
  line-height: $line-height-18;
  margin-bottom: 8px;
}

.custom-select .css-1s2u09g-control {
  min-width: 744px !important;
}

//for grid
.user-details-wrapper__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  &--grid-gap-vendor-profile {
    gap: 0 24px;
  }
  &--org-profile {
    gap: 0 24px;
  }
}

.project-form-wrapper {
  .user-details-wrapper__form-group {
    > label, .checkpoint-active-details__label {
      font-size: $font-size-14;
      line-height: $line-height-18;
      font-weight: $medium-font-weight;
      margin-bottom: 8px;
      margin-left: 0;

      &.form-label-error {
        color: $validation-font-color !important;
      }
    }

    .dropdownselect-form {
      .selected-value-box {
        height: 44px;
        padding: 13px 16px;

        .search_input {
          font-size: $font-size-14;
          line-height: $line-height-18;
          color: $theme-font-heading-color;
        }
        
        .right_container {
          top: 7px;
          right: 8px;
        }
      }
    }

    textarea {
      padding: 13px 16px;
      font-size: $font-size-14;
      line-height: $line-height-18;
      color: $theme-font-heading-color;
    }

    input {
      &.form-control {
        padding: 13px 16px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        color: $theme-font-heading-color !important;
        height: 44px;
      }
    }

    .css-b62m3t-container {
      .css-13cymwt-control, .css-t3ipsp-control {
        min-height: 44px;
        align-items: center;
        padding: 0px 2px 0 16px;
      }
      .css-1fdsijx-ValueContainer, .css-3w2yfm-ValueContainer  {
        margin-left: 0;
        padding-left: 0;
        padding-top: 0;
      }
    }
  }
}

.org-profile-wrapper__prefered-text {
  display: block;
  margin-top: 8px;
  color: rgba(var(--primary-color), 1);
  font-size: $font-size-12;
  line-height: $line-height-16;
}

.organization_container {
  .checkpoint-active-details-wrapper__form-wrapper {
    .checkpoint-active-details__label {
      margin-left: 0;
      font-size: $font-size-14;
      line-height: $line-height-18;
      margin-bottom: 8px;
      color: $theme-font-primary-color;

      &.form-label-error {
        color: $validation-font-color !important;
      }
    }

    input {
      &.form-control {
        height: 44px;
        padding: 12px 16px;
        font-size: $font-size-16;
        line-height: $line-height-18;
      }
    }

    textarea {
      padding: 12px 16px;
      font-size: $font-size-16;
      line-height: $line-height-18;
    }

    .css-b62m3t-container {
      .css-ymdd3v-control {
        height: 44px;

        .css-1fdsijx-ValueContainer {
          padding-left: 16px;
        }
      }

      .css-14dkpol-control {
        height: 44px;

        .css-1fdsijx-ValueContainer {
          padding-left: 16px;
        }
      }
    }

    .dropdownselect-form {
      .selected-value-box {
        height: 44px;
        padding: 12px 16px;
        font-size: $font-size-16;
        line-height: $line-height-18;
      }

      .right_container {
        top: 7px;
      }
    }
  }
}
.org-profile-wrapper-image {
  position: relative;
  .org-profile-wrapper-change-text {
    font-size: $font-size-12;
    line-height: $line-height-16;
    text-decoration: underline;
    color: $theme-font-primary-color;
    position: absolute;
    right: 8px;
    top: 8px;
  }
}