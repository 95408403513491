@import "@scss/common/variables";
.common-sticky-subheader {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  background-color: $white-color;
  z-index: 5;
}
.inner-page-navigation-with-back-cta-button__header-title {
  font-size: 16px;
  font-weight: normal;
}

.inner-page-navigation-with-back-cta-button__header {
  margin-left: 32px;
}

.inner-page-navigation-with-back-cta-buttons__navigation_left {
  left: 20%;
  transform: translateX(-50%);
  bottom: 0;
}

.inner-page-navigation-with-back-cta-buttons {
  border-bottom: 1px solid #dadce0;
  margin-top: 6px;
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item-link {
  font-size: 14px;
  color: $theme-font-primary-color;
  display: inline-block;
  padding-bottom: 16px;
  position: relative;
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.inner-page-navigation-with-back-cta-buttons__nav-list-item.active {
  color: rgba(var(--primary-color), 1);
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(var(--primary-color), 1);
}
.inner-page-navigation-with-back-cta-buttons__nav-list-item.active:after {
  background-color: rgba(var(--primary-color), 1);
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item {
  margin-right: 32px;
  &:last-child {
    margin: 0;
  }
}
.inner-page-navigation-with-back-cta-buttons__navigation_left_temp {
  // left: 20%;
  // transform: translateX(-50%);
  bottom: 0;
}
.inner-page-navigation-with-back-cta-buttons__nav-list-item_temp {
  // margin-right: 32px;
  &:last-child {
    margin: 0;
  }
  &.active {
    color: rgba(var(--primary-color), 1);
    padding-bottom: 16px;
    border-bottom: 2px solid rgba(var(--primary-color), 1);
  }
}
.report_count {
  position: absolute;
  right: -20px;
  top: -10px;
  background-color: #ec6861;
  font-size: 12px;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reimbursement_container {
  border: 1px solid #a2c2b5;
  border-radius: 5px;
  overflow: hidden;
  .reimbursement_container_header {
    border-bottom: 1px solid #96c0af;
    background-color: #e4f7f1;
  }
}

.border-dotted {
  border-bottom: 1px dashed #eee !important;
}
.doc_view {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  border: 1px solid #efefef;
  overflow: hidden;
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.accordian_header-text::before {
  display: none;
}
.accordion-button{
  padding: 0;
  &:focus{
    box-shadow: none !important;
  }
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: none !important;
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button:not(.collapsed){
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.accordion-item{
  border: none !important;
  &:focus{
    background-color: none !important;
  }
}
.accordion-body{
  padding: 0 !important;
}

.report-detail-wrapper_left_container_list_item {
  .doc_view {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  }
}

.edit-expense-amount-edit-group {
  .form-group {
    margin-bottom: 0;
    width: calc(100% - 50px);

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: calc(100% - 50px);

      &:focus {
        border-color: #dadce0;
      }
    }

    .input-group-text {
      max-width: 50px;
      min-width: 50px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  label {
    &.on-hover {
      margin: 0;
    }
  }
}

.edit-expense-amount-edit-group {
  .input-group-text {
    width: 75px;
    justify-content: center;
  }

  .form-group {
    width: calc(100% - 75px);
  }
}