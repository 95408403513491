@import "normalize.scss";
@import "extends.scss";
@import "variables.scss";

//---------------------- image icon styles -------------------//
//--close-//
.close-image_container {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease;
  cursor: pointer;
  .close_image {
    height: 20px;
    width: 20px;
  }
  &:hover {
    background-color: #e8f0fe !important;
  }
}

.common_image_container {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease;
  cursor: pointer;
  .common_image {
    height: 18px;
    width: 18px;
    :hover {
      fill: #276ef1 !important; /* Color to be applied on hover */
    }
  }
  .common_image_bold {
    height: 22px;
    width: 22px;
    :hover {
      fill: #276ef1 !important; /* Color to be applied on hover */
    }
  }
  &:hover {
    background-color: #e8f0fe !important;
  }
}

.text_button_container {
  height: 40px;
  border: 1px solid #dadce0;
  border-radius: 5px;
  cursor: pointer;
  img {
    height: 24px;
  }
  p {
    font-size: 14px;
    color: #5f6368;
  }
}
.header_square {
  border: 1px solid #dadce0;
  height: 40px;
  min-width: 40px;
  max-width: 176px !important;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .header_square_image {
    height: 18px;
    width: 18px;
  }
}
.reimbursement_button{
  border: none;
  border: 1px solid #dadce0;
  height: 40px;
  min-width: 40px;
  max-width: 176px !important;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  background: #408dfb;
  font-size: 14px;
  &:hover{
    background: #2c81f8;

  }
}

.image{
  &__100{
    height: 100px;
    width: 100px;
  }
}

//---------------------- text styles -------------------//
.label_text {
  color: #5f6368;
  font-size: 12px;
  font-weight: 400;
}
.header_text {
  color: #3c4043;
  font-size: 16px;
  font-weight: 500;
  &__12 {
    font-size: 12px;
    font-weight: 500;
    color: #3c4043;
  }
  &__14 {
    font-size: 14px;
    font-weight: 500;
    color: #3c4043;
  }
  &__16 {
    font-size: 16px;
    font-weight: 500;
    color: #3c4043;
  }
  &__17 {
    font-size: 17px;
    font-weight: 500;
    color: #3c4043;
  }
  &__18 {
    font-size: 18px;
    font-weight: 500;
    color: #3c4043;
  }
  &__19 {
    font-size: 19px;
    font-weight: 500;
    color: #3c4043;
  }
  &__20 {
    font-size: 20px;
    font-weight: 500;
    color: #3c4043;
    line-height: 25px;
  }
  &__20_0_weigth {
    color: #3c4043;
    font-size: 20px;
    font-weight: 400;
  }
  &__22 {
    font-size: 22px;
    font-weight: 500;
    color: #3c4043;
  }
  &__24 {
    font-size: 24px;
    font-weight: 500;
    color: #3c4043;
  }
}
.desc_text {
  color: #5f6368;
  font-size: 14px;
  margin-top: 6px;
  &__11 {
    font-size: 11px;
    font-weight: 500;
    color: #5f6368;
  }
  &__14 {
    font-size: 14px;
    color: #5f6368;
  }
  &__16 {
    font-size: 16px;
    font-weight: 500;
    color: #5f6368;
  }
  &__18 {
    font-size: 18px;
    font-weight: 500;
    color: #5f6368;
  }
  &__19 {
    font-size: 19px;
    font-weight: 500;
    color: #5f6368;
  }
  &__20 {
    font-size: 20px;
    font-weight: 500;
    color: #5f6368;
  }
}
.small_text {
  color: #5f6368;
  font-size: 12px;
  &__10 {
    color: #5f6368;
    font-size: 10px;
  }
  &__12 {
    color: #5f6368;
    font-size: 12px;
  }
  &__13 {
    color: #5f6368;
    font-size: 13px;
  }
  &__14 {
    color: #5f6368;
    font-size: 14px;
  }
  &__15 {
    color: #5f6368;
    font-size: 15px;
  }
  &__16 {
    color: #5f6368;
    font-size: 16px;
  }

  &__blue_14 {
    color: #276ef1;
    font-size: 14px;
  }
}
.header_text_blue {
  color: #276ef1;
  font-size: 16px;
  font-weight: 700;
}
.width {
  &__90 {
    width: 90%;
  }
  &__95 {
    width: 95%;
  }
}

//---------------------- border styles -------------------//

.vertical_line {
  height: 100%;
  border-left: 1px solid #dadce0;
  margin-left: 10px;
}
.horizontal_line {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dadce0;
}
.vertical-line {
  border-left: 2px dashed #888; /* Adjust width and color as needed */
  height: 100%; /* This ensures the line stretches to the full height */
  display: inline-block; /* Ensures the line only takes the required space */
  content: "";
  vertical-align: middle; /* Helps with vertical alignment if used alongside text */
}

//--------------------- checkbox styles -------------------//

.common-checkbox {
  height: 16px;
  width: 16px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

//------------------- navigation styles ---------------------//
ul.navbar_policy.nav-tabs {
  padding: 16px 0 0 16px;
  cursor: pointer;
  width: 100%;
}

li.navitem_policy {
  background-color: white;
  width: 200px !important;
  height: 40px;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border: 1px solid var(--theme-border-color) !important;
  &:hover {
    // background-color: #edf4ff !important;
  }
  margin-right: 2px;
  border-bottom: none !important;
  // justify-content: space-around;
}

a.active.navlink_policy {
  border-radius: 0;
  height: 41px !important;
  background-color: white !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border: 1px solid #dadce0 !important;
  border-bottom: none !important;
  color: #33475b !important;
}
.navlink_policy {
  color: #707070;
  font-weight: $medium-font-weight;
  // background-color: #f7fbff;
  height: 40px !important;
  border-radius: 4px 4px 0 0;
  // background-color: #f4f8ff !important;
  border-bottom: none !important;
  font-size: 14px;
}
.position-relative {
  position: relative;
}
.border-radius {
  &__4 {
    border-radius: 4px;
  }
  &__5 {
    border-radius: 5px;
  }
  &__10 {
    border-radius: 10px;
  }
}
.flex-wrap_wrap {
  display: flex;
  flex-wrap: wrap;
}
.border_blue {
  border: 1px solid #408dfb !important;
  border-radius: 5px;
}
.border_white{
  border: 2px solid #fff !important;
  border-radius: 5px;
}
.border_red {
  // border: 1px solid #d72e38 !important;
  border: 1px solid #dc979b !important;
  border-radius: 5px;
}

.border_bottom {
  border-bottom: 1px solid #efefef !important;
}
.border_bottom-dark {
  border-bottom: 1px solid #dadce0 !important;
}

.dashed-border {
  border: 1px dashed #dadce0;
}

.border_bottom_dashed {
  border-bottom: 1px dashed #dadce0;
}

.profile_circle {
  background-color: #2d5743;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
  .title {
    color: white;
    font-size: 14px;
  }
}

.vr {
  width: 1px;
  display: table-cell;
  background-color: black;
  height: auto;
}

.w {
  &-30 {
    width: 30%;
  }
  &-35 {
    width: 35%;
  }
  &-40 {
    width: 40%;
  }
  &-45 {
    width: 45%;
  }
  &-60 {
    width: 60%;
  }
  &-65 {
    width: 65%;
  }
  &-70 {
    width: 70%;
  }
  &-80 {
    width: 80%;
  }
}

.common-dropdown.dropdown .show {
  display: block;
  /* max-height: 250px; */
  min-height: 139px;
  overflow-y: auto;
  margin-top: 52px;
}

.text-right{
  text-align: right;
}