@import '@scss/common/variables';
@import '@scss/common/extends';
//complete task
.compelte-task-wrapper {
	position: fixed;
	box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
	top: 10px;
	height: 100%;
	max-width: calc(100% - 350px);
	width: 100%;
	right: 10px;
	background-color: $white-color;
	z-index: 20;
	transform: translateX(120%);
	transition: all 0.8s ease-in-out;
	border-left: 1px solid $theme-border-color;
	max-height: 100vh;
	overflow-y: auto;
	z-index: 1000;
}

.compelte-task-wrapper-add {
	position: fixed;
	box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
	top: 10px;
	height: 100%;
	max-width: calc(40%);
	width: 100%;
	right: 10px;
	background-color: $white-color;
	z-index: 20;
	transform: translateX(120%);
	transition: all 0.8s ease-in-out;
	border-left: 1px solid $theme-border-color;
	max-height: 100vh;
	overflow-y: auto;
}

.compelte-task-wrapper--active {
	transform: translateX(0);
}
.compelte-task-wrapper__header {
	padding: 12px;
	background-color: #1a73e8;
}
.compelte-task-wrapper__title {
	color: #ffffff;
}

.compelte-task-wrapper__completion {
	padding: 24px;
	border-right: 1px solid $theme-border-color;
}
.compelte-task-wrapper__response {
	padding: 24px;
	border-right: 1px solid $theme-border-color;
}
.compelte-task-wrapper__form-group {
	margin: 16px 0;
}
.compelte-task-wrapper__status {
	max-width: 364px;
	width: 100%;
	border-radius: $base-border-radius;
	height: 44px;
	line-height: 44px;
	padding: 0 16px;
	color: $theme-primary-color;
	border: 1px solid $theme-border-color;
	background-color: #f7f7f7;
	&--width {
		max-width: 100%;
		color: $theme-font-primary-color;
	}
}
.compelte-task-wrapper__label {
	margin-bottom: 8px;
	font-weight: $medium-font-weight;
	color: $theme-font-heading-color;
}
.compelte-task-wrapper__label_popup {
	font-weight: $medium-font-weight;
	color: $theme-font-heading-color;
}
.compelte-task-wrapper__common-title {
	color: $theme-primary-color;
	font-weight: $medium-font-weight;
}

.compelte-task-wrapper__text-area {
	padding: 10px;
	border: 1px solid $theme-border-color;
	border-radius: $base-border-radius;
	&::placeholder {
		color: $theme-border-color;
	}
}
.custom-file {
	border: 1px solid $theme-border-color;
	border-radius: $base-border-radius;
	padding: 24px;
	min-width: 690px;
	width: 100%;
}
.custom-file--attach {
	background-color: #f7f7f7;
	padding: 0 16px;
	height: 32px;
	line-height: 32px;
	border-radius: $base-border-radius;
	cursor: pointer;
}
.compelte-task-wrapper__uploaded {
	width: 100%;
	height: 44px;
	line-height: 44px;
	padding: 0 16px;
	background-color: #f7f7f7;
	border-radius: $base-border-radius;
	span{
		font-size: 13px;
	}
	&:not(:last-child) {
		margin-bottom: 8px;
	}
}
