@import "@scss/common/variables";

//admin-user-wrapper

.admin-wrapper {
  width: 100%;

  .admin-wrapper__add-search {
    background-color: #ff9933;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    svg {
      margin: auto;
    }
  }
  .admin-wrapper__search {
    padding: 8px 17px;
    .admin-wrapper__search-title {
      font-size: 10px;
      color: $theme-font-primary-color;
      margin-top: 5px;
    }
  }
  table.admin-wrapper__user-table {
    border: 1px solid $theme-border-color;
    max-height: calc(100vh - 185px);
    overflow-y: auto;
    thead {
      th {
        color: $theme-font-primary-color;
        font-size: 12px;
        font-weight: 700;
        width: 130px;
        &:first-child {
          width: 50px;
        }
        &:nth-child(2) {
          width: 216px;
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(2) {
            color: rgba(var(--button-primary), 1);
          }
        }
      }
    }
    th,
    td {
      padding: 15px 9px;
      border-right: 1px solid $theme-border-color;
      border-bottom: 1px solid $theme-border-color;
      &:nth-child(1) {
        padding-left: 28px;
        border-right: transparent;
      }
      &:nth-child(2) {
        padding-left: 0px;
      }
    }
  }
}
