.analytics_section {
  overflow: auto;
  max-height: calc(100vh - 116px);
  .analytics_container {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 10px 18px;
    width: 70%;
    .analytics_item {
      padding: 8px;
      min-width: 200px;
      border: 1px solid #efefef;
      border-radius: 5px;
      margin-right: 10px;
      &:hover {
        background-color: #f7faff !important;
        transition: all 500ms ease;
      }
    }
  }
}

.analytics-create-container{
  overflow: auto;
  max-height: calc(100vh - 116px);
}
.analytics_box{
  height: 40vh;
  overflow: auto;
  width: 100%;
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 5px;
}
.create-box{
  cursor: pointer;
  &:hover{
    background-color: #eff7ff
  }
}
.analytics_box_header{
  border-bottom: 1px solid #dadce0;
}