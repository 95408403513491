.svg_image {
  height: 18px;
  width: 18px;
  :hover {
    fill: #276ef1 !important; /* Color to be applied on hover */
  }
}

.main_image_container {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms ease;
  cursor: pointer;
  fill: lightgray;
  .main_image {
    height: 20px;
    width: 20px;
  }
}

.delete_image_container {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms ease;
  cursor: pointer;
  .delete_image {
    height: 18px;
    width: 18px;
  }
  &:hover {
    // background-color: #eea8ad !important;
    background-color: #eccbcd !important;
    fill: #d7242e;
  }
}

.delete_small_image_container {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms ease;
  cursor: pointer;
  fill: #5f6368;
  .delete_small_image {
    height: 12px;
    width: 12px;
  }
  &:hover {
    // background-color: #eea8ad !important;
    background-color: #eccbcd !important;
    fill: #d7242e;
  }
}

.edit_image_container {
  // margin-right: 10px;
  // margin-right: 32px;
  margin-right: 15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms ease;
  cursor: pointer;
  .edit_image {
    height: 22px;
    width: 22px;
  }
  &:hover {
    // background-color: #eea8ad !important;
    // background-color: #c8d2e7 !important;
    background-color: #d0dbf1;
    fill: #276ef1;
  }
}
