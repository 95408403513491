@import "@scss/common/variables";

.reimbursement_create_container {

  .reimbursement_create_container_section{
    overflow: auto;
    max-height: calc(100vh - 146px);
  }
  .reimbursement_form_container {
    // width: 40%;
    // margin-left: 10vw;
    // margin-top: 5vh;
    width: calc(50% - 52px);
    margin-left: 26px;
    margin-top: 24px;
  }
  .reimbursement_right_container{
    // margin-left: 3vw;
    .success_container{
        background-color: #e5f7f1;
    }
  }
}


.reimbursement-form-wrap {
  .user-details-wrapper__form-group {
    .checkpoint-active-details__label {
      font-size: $font-size-14;
      line-height: $line-height-18;
      font-weight: $medium-font-weight;
      margin-bottom: 8px;
      margin-left: 0;

      &.form-label-error {
        color: $validation-font-color !important;
      }
    }

    input.form-control {
      height: 44px;
    }
  }
}