@import "@scss/common/_variables.scss";

.datatable_main_container {
  overflow: auto;
  max-height: calc(100vh - 64px - 50px - 112px);
  height: calc(100vh - 64px - 50px - 112px);
  width: 100%;
  margin: auto;
}
.datatable_main_container_notifiction {
  overflow: auto;
  max-height: calc(100vh - 64px - 50px - 112px - 44px);
  height: calc(100vh - 64px - 50px - 112px - 44px);
  width: 100%;
  margin: auto;
}
.datatable-table_top_container {
  width: 95%;
  margin: auto;
  height: 44px;
  position: sticky;
  background-color: #f4f8ff;
  border: 1px solid #dadce0;
  display: flex;
  align-items: center;
  .datatable-table_top_container_items {
    width: 100%;
  }
}
.datatable_container {
  max-height: calc(100vh - 64px - 60px - 132px - 32px);
  overflow: auto;
  width: 95%;
  margin: auto;
  border-bottom: 1px solid #dadce0;
}

.datatable_table {
  overflow: auto;
}
.datatable_table_thead {
  .datatable_table_th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 7;
    height: 60px;
    background-color: #fff;
    transition: all 500ms ease-in;
    font-family: $font-family;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    h6 {
      font-weight: 500;
      color: #02052b;
    }

    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12;
    }
  }
}

.datatable_table_th:nth-child(1) {
  text-align: center;
}

.datatable_table_td:nth-child(1) {
  align-items: center;
  text-align: center !important;
  display: flex;
  justify-content: center;
  width: 70px !important;
  // border-bottom: 1px solid #efefef !important;
}
.datatable_table_td_common:nth-child(1){
  align-items: center;
  text-align: center !important;
  display: flex;
  justify-content: center;
  // width: 70px !important;
}
.datatable_table_tr {
  &:hover {
    background-color: #f7faff !important;
  }
  .datatable_table_td {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #efefef;
    width: 400px;
    cursor: pointer;
    max-width: 300px;
    h6 {
      font-weight: 500;
      color: #5f6368;
      font-size: 15px;
    }
    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12px;
    }
  }
  .datatable_table_td_common {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #efefef;
    width: 400px;
    cursor: pointer;
    max-width: 300px;
    h6 {
      font-weight: 500;
      color: #5f6368;
      font-size: 15px;
    }
    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12px;
    }
  }
}
table {
  table-layout: fixed;
}
th,
td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
th:after,
th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

th:before {
  // border-bottom: 1px solid #dadce0;
  top: -1px;
  // border-top: 1px solid #dadce0;
}

th:after {
  bottom: -1px;
  border-bottom: 2px solid #dadce0;
}


//Written by Joseph
.datatable_table {
  .datatable_table_thead {
    .datatable_table_th, h6 {
      font-size: 16px;
      line-height: 18px;
      color: $table-head-font-color;
    }

    .datatable_table_th {
      padding-top: 24px;
      padding-bottom: 16px;
      vertical-align: top;
      p {
        color: $theme-font-primary-color;
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
      }
    }
  }

  .datatable_table_tbody {
    .datatable_table_tr {
      .datatable_table_td {
        font-size: 16px;
        line-height: 20px;
        color: $theme-font-primary-color;
        height: auto;
        // padding: 17px 10px 15.5px;
        // height: 60px;

        &:nth-child(1) {
          height: 73px;
          display: table-cell; // coz of d-flex the border the was missalligned
          min-width: 70px;
        }

        h6 {
          font-size: 16px;
          line-height: 20px;
          color: $theme-font-primary-color;
        }

        p {
          font-size: 12px;
          line-height: 16px;
          margin-top: 3px;
        }
      }
    }
  }
}

.datatable_main_container, .datatable_main_container_notifiction {
  padding: 0 26px;
  background: #FDFEFF;

  .datatable_table {

    .datatable_table_thead {
      .datatable_table_th  {
        background: #FDFEFF;
      }
    }

    .datatable_table_tr {
      background: #FDFEFF;
      // :hover {
      //     background-color: #F7FAFF;
      //     .datatable_table_th, .datatable_table_td {
      //       background: #F7FAFF;
      //     }
      // }
    }
  }
}