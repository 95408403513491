@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.create-user-form-body__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.create-user-form-body__label {
  font-size: 14px;
}
.form-group {
  display: block;
  margin-bottom: 8px;
}
.create-user-form-body__roles-wrapper {
  margin-top: 8px;
}

.create-user-form-body__roles-wrapper-title {
  margin-bottom: 8px;
}

.create-user-form-body__add-roles-list-cta {
  color: var(--theme-button-primary-color);
  font-weight: $medium-font-weight;
  margin: 12px 0 24px;
  img {
    margin-right: 8px;
  }
}

.create-user-form-body__show-more-roles {
  color: var(--theme-blue-color);
  font-weight: $medium-font-weight;
}
.error-text {
  color: rgb(236, 55, 55);
  font-size: 12px;
  margin-top: 8px;
}

.form-wrapper__form-group--error {
  .custom-form-control {
    border-color: $red-color;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $theme-border-color;
    }
  }
}

//signup form css

.signup-form-wrapper {
  margin: auto;
  max-width: 364px;
  width: 100%;
}

.signup-form-wrapper__form-group .custom-form-control {
  // height: 56px;
  // line-height: 56px;
  // padding-left: 48px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  @extend %transition;
  &:focus {
    box-shadow: none;
    border-color: $theme-button-primary-color;
  }
  &::placeholder {
    color: $theme-border-color;
  }
  // @include mobile {
  //   height: 44px;
  // }
}
.signup-form-wrapper__form-group .form-label {
  color: #5f6368 !important;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}
.custom-form-control-textarea {
  width: 100%;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250);
  // background-color: #F4F8FF !important;
  // height: 44px;
  line-height: 18px;
  padding: 10px 16px;
  &::placeholder {
    // @include genearteRemFontSize(16);
    font-size: 16px;
    color: $theme-border-color;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid #408dfb;
    background-color: white !important;
  }
}
.custom-form-control-date {
  width: 100%;
  border: 0.5px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250);
  // background-color: #F4F8FF !important;
  // height: 44px;
  line-height: 18px;
  padding: 10px 16px;
  &::placeholder {
    // @include genearteRemFontSize(16);
    font-size: 16px;
    color: #dadce0 !important;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid #408dfb;
    background-color: white !important;
  }
}
.image-icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}
.signup-form-details {
  max-width: 364px;
  width: 100%;
  margin: 0 auto;
}
.signup-title {
  // @include genearteRemFontSize(22);
  font-size: 16px;
  font-weight: $normal-font-weight;
  margin-bottom: 24px;
}
.signup-text {
  max-width: 330px;
  margin: 16px 0 0;
  color: $theme-primary-color;
}
.signup-text span {
  color: $theme-button-primary-color;
}

.error-text {
  color: rgb(236, 55, 55);
  font-size: 12px;
  margin-top: 8px;
}

.signup-form-wrapper__form-group--error {
  .custom-form-control {
    border-color: $red-color;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $theme-border-color;
    }
  }
}
