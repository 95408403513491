@import '@scss/common/variables';

.add-popup-wrapper__popup {
	margin: auto;
	min-height: 292px;
	// max-height: 292px;
	width: 100%;
	box-shadow: 4px 4px 10px #00000029;
	border-radius: 4px;
	// padding: 93px 47px 20px;
	padding: 34px 47px 39px;
}

.add-popup-wrapper__popup--no-max-height {
	height: initial;
	padding: 24px;
}

.add-popup-wrapper__form-group {
	label.add-popup-wrapper__label {
		color: $black-color;
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 7px;
	}
}

.add-popup-wrapper__cta-wrapper {
	text-align: right;
	margin-top: 40px;

	.add-popup-wrapper__cta-btn {
		font-size: 16px;
		padding: 10px 24px;
		border-radius: 4px;
		border: none;
		border: 1px solid $theme-border-color;
		&--secondary {
			color: rgba(var(--button-primary), 1);
			background-color: $white-color;
			transition: 0.3s all ease-in-out;

			margin-right: 16px;

			&:hover {
				background-color: rgba(var(--button-primary), 1);
				color: $white-color;
				border-color: transparent;
			}
		}

		&--primary {
			background-color: rgba(var(--button-primary), 1);
			color: $white-color;
			transition: 0.3s all ease-in-out;

			&:hover {
				color: rgba(var(--button-primary), 1);
				background-color: $white-color;
				border: 1px solid $theme-border-color;
			}
		}
	}
}
.common-popup-spacing__popup {
	padding: 42px 47px;
}

.add-popup-wrapper {
	.add-popup-wrapper__form-group {
		.form-label {
			font-size: $font-size-14;
			line-height: $line-height-18;
			font-weight: $medium-font-weight;
			margin-bottom: 8px;
			margin-left: 0;

			&.form-label-error {
				color: $validation-font-color !important;
			}
		}

		input {
			&.form-control {
			  padding: 13px 16px;
			  font-size: $font-size-14;
			  line-height: $line-height-18;
			  color: $theme-font-heading-color !important;
			  height: 44px;
			}
		  }
	}
}