@import "@scss/common/variables";

.uploading-wrapper {
  width: 100%;
  // height: calc(80vh - 135px);
  background-color: $white-color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-wrapper__details {
  margin: auto;
}
.uploading-image {
  height: 200px;
  width: 200px;
  text-align: center;
  transition: 0.3ms all ease-in-out;
}
.no-file-image{
  text-align: center;
  transition: 0.3ms all ease-in-out;
}
