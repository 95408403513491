@import "@scss/common/_variables.scss";

.basic-information_container{
    overflow: auto;
    max-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
}

.basic_information_grid{
    flex-wrap: wrap;
    display: flex;
}

.client-travel-profile-wrap {
    .checkpoint-active-details-wrapper__form-wrapper {
        padding-top: 24.5px;
    }
    .client-travel-profile_main-title {
        text-align: center;
        font-size: $font-size-20;
        line-height: $line-height-25;
        font-weight: $medium-font-weight;
        margin-bottom: 16px;
    }

    .info-alert {
        font-size: $font-size-12;
        line-height: $line-height-18;
        padding: 6px;
        background-color: #E8F0FE;
        border: 1px solid #E8F0FE;
        color: #204891;
        text-align: center;
        margin-bottom: 16px;
    }

    .client-travel-profile-form-wrapper {
        gap: 0 16px;

        .form-group {
            label {
                margin-bottom: 0;
            }
        }

        
        .client-travel-profile-labelfield-wrap {
            margin-bottom: 16px;

            // &.form-group {
            //     margin-bottom: 0;
            // }

            &.client-travel-profile-labelfield-wrap_dob {
                .form-group {
                    > div {
                        // border: 0 !important;

                        .react-datepicker-wrapper {
                            .react-datepicker__input-container  {
                                input {
                                    // border: 1px solid $theme-border-color !important;
                                    height: 42px;
                                }
                            }
                        }
                    }
                }
            }

            > label {
                font-size: $font-size-14;
                line-height: $line-height-18;
                font-weight: $medium-font-weight;
                margin-bottom: 8px;
                margin-left: 0;
                color: $theme-font-primary-color;

                &.form-label-error {
                    color: #b94a48;
                }
            }

            .form-group {
                margin-bottom: 0;
            }

            .form-error {
                margin-top: 0;

                .srv-validation-message {
                    margin-top: 4px;
                }
            }


            input {
                &.form-control {
                    height: 44px;
                    font-size: $font-size-16;
                    line-height: $line-height-18;
                    padding: 12px 16px;
                }
            }

            .selected-value-box {
                height: 44px;
                font-size: $font-size-16;
                line-height: $line-height-18;
                padding: 12px 16px;

                .search_input {
                    font-size: $font-size-16;
                    line-height: $line-height-18;
                }

                .right_container {
                    top: 9px;
                    right: 8px;

                    .arrow_image_container {
                        .arrow_image {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
            }
        }

        &.client-travel-profile-form-address_wrapper {
            display: flex;
            flex-wrap: wrap;

            .basic_information_grid {
                .client-travel-profile-labelfield-wrap {
                    width: calc(50% - 16px);
                    margin: 0 16px 16px 0;
                }
            }

            .address-home-work-wrapper {
                &:first-child {
                    margin-bottom: 24px;
                    position: relative;
                    padding-bottom: 8.5px;

                    &:after {
                        content: "";
                        background-image: linear-gradient(to right, #dadce0 50%, rgba(255, 255, 255, 0) 0%);
                        background-position: top;
                        background-size: 10px 3px;
                        background-repeat: repeat-x;
                        height: 1px;
                        border: 0;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    
}


.client-travel-profile-border-spacing {
    margin: 8.5px 0 24.5px;
}