@import '@scss/common/variables';
.client-list-details-wrapper {
	width: 100%;
	position: relative;
	padding-bottom: 100px;
}

.client-list-details-wrapper__header {
	width: 100%;
	margin: 0px auto;
	padding: 25px 0 16px;
	border-bottom: 1px solid $theme-border-color;
	.client-list-details-wrapper__nav-list {
		max-width: 510px;
		width: 100%;
		margin: 0 auto;
	}
	.client-list-details-wrapper__nav-list-item {
		&:not(:last-child) {
			margin-right: 32px;
		}
		.client-list-details-wrapper__nav-list-item-link {
			font-size: 14px;
			color: $theme-font-primary-color;
			&.active {
				position: relative;
				color: rgba(var(--primary-color), 1);
				&::after {
					content: '';
					position: absolute;
					bottom: -16px;
					left: 0;
					right: 0;
					height: 1px;
					width: 100%;
					background-color: rgba(var(--primary-color), 1);
				}
			}
		}
	}
}

.client-list-details-wrapper__header.client-list-details-wrapper__header--no-padding {
	padding: 0;
}

.common-sticky-header {
	background-color: white;
	position: sticky;
	z-index: 7;
	// top: 6px;
	top: 0px;
}
.common-trips-sticky-header {
	background-color: white;
	position: sticky;
	z-index: 7;
	top: 0px;
}
