@import "@scss/common/variables";

.currency_container {
  // max-width: 744px;
  margin: 0 auto;
  width: 100%;
  margin-top: 20px;
  .currency_header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 20px;
    .currency_header_exchange {
      padding: 10px;
      border: 1px solid #efefef;
      border-radius: 5px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .currency_header_p {
        margin-right: 20px;
      }
    }
    .separator {
      margin-top: 10px;
    }
    .currency_header_add {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border: 1px solid #efefef;
      border-radius: 5px;
      .currency_header_img {
        margin-right: 10px;
      }
      .currency_header_p {
        color: #276ef1;
      }
      cursor: pointer;
    }
  }
}

.currency_table_container {
  overflow: auto;
  max-height: calc(100vh - 64px - 50px - 143px);
  height: calc(100vh - 64px - 50px - 143px);
}

.dot-holder-seperator {
  position: relative;
  margin: 0 8px;

  &:after {
    content: "";
    background-color: $theme-font-primary-color;
    width: 3px;
    height: 3px;
    display: block;
    border-radius: 50%;
  }
}

.currency_container {
  
  .currency_header {
    border-bottom: 1px solid $theme-border-color;
    padding-bottom: 8px;
    margin: 0 26px 0;

    .currency_header_add {
      height: 40px;
    }
  }

  .currency_table_container {
    padding: 0 26px;
  }
}

.dd-enabled-exchange-rates {
  border: 1px solid $theme-border-color;
  // padding: 10px;
  border-radius: 4px;
  width: auto;
  position: absolute;
  z-index: 9;
  bottom: -89px;
  right: -2px;
  min-width: 160px;
  background: #fff;
  cursor: pointer;

  span {
    width: 100%;
  }
}