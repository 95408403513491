@import "@scss/common/normalize.scss";
@import "@scss/common/extends.scss";
@import "@scss/common/common.scss";

//------------------client------------------//
.dashbaord-wrapper_section_container {
  width: 100%;
  // padding-left: 5%;
  // padding-right: 5%;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 2%;
  overflow: auto;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  .dashbaord-wrapper_info-section {
    border: 1px solid #dadce0;
    padding: 20px;
    border-radius: 5px;
  }
  .dashbaord-wrapper_info-section2 {
    display: flex;
    margin-top: 2%;
    .dashbaord-wrapper_info-section2_left {
      flex-grow: 1;
      width: 40%;
      min-height: 20vh;
      border: 1px solid #dadce0;
      border-radius: 5px;
      overflow: hidden;
    }
    .dashbaord-wrapper_info-section2_right {
      flex-grow: 1;
      width: 60%;
      min-height: 20vh;
      border: 1px solid #dadce0;
      margin-left: 2%;
      border-radius: 5px;
      overflow: hidden;

      .dashbaord-wrapper_info-section2_right_body {
        display: flex;
        .dashbaord-wrapper_info-section2_right_body_1 {
          flex-grow: 1;
          width: 33%;
          border-right: 1px solid #dadce0;
          min-height: 20vh;
          display: flex;
          align-items: center;
          justify-content: center;
          .dashbaord-wrapper_info-section2_right_body_circle {
            border-radius: 50%;
            height: 50px;
            width: 50px;
            border: 1px solid #efefef;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background-color: #e8f0fe;
            }
          }
        }
      }
    }
  }
  .dashbaord-wrapper_info-section3 {
    border: 1px solid #dadce0;
    height: 50vh;
    border-radius: 5px;
    overflow: hidden;
  }
}

.dashboard_quickadd_section {
  margin-top: 16px;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 24px;
  
  &__blue {
    border: 1px dashed #b8cdf0;
    width: 234px;
    height: 167px;
    margin-right: 16px;
    border-radius: 4px;
  }
  &__green {
    border: 1px dashed #92e6bd;
    width: 234px;
    height: 167px;
    margin-right: 16px;
    border-radius: 4px;
  }
  &__orange {
    border: 1px dashed #f5bc82;
    width: 234px;
    height: 167px;
    border-radius: 4px;
    margin-right: 16px;
  }
}
.dasboard_info_item_section{
  margin-top: 16px;
  flex-wrap: wrap;
  cursor: pointer;
  &__item{
    min-height: 84px;
    min-width: 234px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    margin-right: 16px;
  }
}

//------------------admin------------------//

.admin_dashbaord-wrapper_info-section2 {
  margin-top: 2%;
  .dashbaord-wrapper_info-section2_left {
    // width: 65%;
    // height: 50vh;

    border: 1px solid #dadce0;
    border-radius: 5px;
    overflow: hidden;
    .line-graph {
      width: 100% !important;
      height: 45vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  // .dashbaord-wrapper_info-section2_right {
  //   flex-grow: 1;
  //   width: 35%;
  //   min-height: 50vh;
  //   border: 1px solid #dadce0;
  //   margin-left: 2%;
  //   border-radius: 5px;
  //   overflow: hidden;

  //   .dashbaord-wrapper_info-section2_right_body {
  //     display: flex;
  //     .dashbaord-wrapper_info-section2_right_body_1 {
  //       flex-grow: 1;
  //       width: 33%;
  //       border-right: 1px solid #dadce0;
  //       min-height: 20vh;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       .dashbaord-wrapper_info-section2_right_body_circle {
  //         border-radius: 50%;
  //         height: 50px;
  //         width: 50px;
  //         border: 1px solid #efefef;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         cursor: pointer;
  //         &:hover {
  //           background-color: #e8f0fe;
  //         }
  //       }
  //     }
  //   }
  // }
}

.admin_dashbaord-wrapper_info-section3 {
  display: flex;
  margin-top: 2%;
  .dashbaord-wrapper_info-section_report {
    flex-grow: 1;
    width: 33%;
    min-height: 50vh;
    border: 1px solid #dadce0;
    border-radius: 5px;
    overflow: hidden;
  }
}

//------------------ common ----------------//
.dashbaord-wrapper_info-section2_left_header {
  border-bottom: 1px solid #dadce0;
  // background-color: #f7f8fb;
}
.dashbaord-wrapper_info-section2_left_section {
  width: 90%;
  margin: 0 auto;

  .border {
    margin-top: 15px;
    margin-bottom: 15px;
    border: none !important;
    border-bottom: 1px dotted #dadce0 !important;
  }
}
.flex-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.lottie_container {
  position: absolute;
  width: 27%;
  right: 10%;
  top: -57%;
  background-color: transparent;
}
.lottie_container_client {
  position: absolute;
  width: 21%;
  right: 16%;
  top: 0% !important;
}



.dashbaord-wrapper_section_container {
  .mt-34 {
    margin-top: 34px;
  }
}

.dashboard_quickadd_section {
  position: relative;

  .add-icon-holder {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}