.reminders-wrap {
    .reminders-content-wrap {
        .form-group {
            margin-bottom: 0;

            .custom-form-control {
                max-width: 135px;
            }
        }
    }
}