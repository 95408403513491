@import "@scss/common/variables";

.effects{
  background-color: rgba(var(--primary-color), 0.06);
  color: rgba(var(--primary-color), 1);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: rgba(var(--primary-color), 1);
  }
}


.header__dropdown .dropdown-content {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #1d4d40;
  color: $white-color;
}
.switch_header__dropdown .dropdown-content {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.switch_header__dropdown_text {
font-size: 12px;
color: #5F6368;
}
.header-wrapper {
  padding: 6px 0;
  border-bottom: 1px solid $theme-border-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white-color;
  z-index: 8;
  // height: 70px;
  height: 64px;
}
.header_logo{
  // height: 40px;
  height: 34px;
  width: auto; // Added to fix on safari
}

.switch-dropdown {
  margin-right: 20px;
}
.switch-dropdown .dropdown-header {
  display: flex;
  justify-content: space-between;
}
.switch-dropdown .dropdown .btn {
  // height: 34px;
  height: 32px;
}
.switch-dropdown .btn-secondary {
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary.dropdown-toggle {
  border: none !important;
}
.switch-dropdown .btn {
  padding: 0 0px;
  border: none;
}
.switch-dropdown .dropdown {
  &:before {
    height: 0;
    width: 0;
    top: 0;
  }
}
.switch-dropdown .dropdown-menu {
  padding: 0;
  transform: translate3d(0, 47.5px, 0) !important;
  min-width: 200px;
}
.switch-dropdown .dropdown-item {
  padding: 0px 0 8px !important;
  margin: 0;
  text-align: center;

  p {
    text-align: center;
  }
  &:hover {
    color: #d7242e;
    background-color: transparent !important;
  }
}
.switch-dropdown .switch-dropdown-item-header {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  p {
    font-size: 16px;
  }
  img {
    height: 19px;
    width: 19px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
      border-radius: 50%;
      padding: 1px;
    }
  }
}
.switch-dropdown .switch-dropdown-item {
  display: flex;
  padding: 7px;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  width: 100%;
  p {
    font-size: 14px;
  }
  &:hover {
    // color: #d7242e;
    background-color: transparent !important;
  }

  &.active {
    @extend .effects;
    // font-size: 16px;
    
  }
}


.bulk-dropdown {
  margin-right: 10px;
}

.bulk-dropdown .dropdown .btn {
  // height: 34px;
  height: 32px;
}
.bulk-dropdown .btn-secondary {
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary.dropdown-toggle {
  border: none !important;
}
.bulk-dropdown .btn {
  padding: 0 0px;
  border: none;
}

.bulk-dropdown .dropdown {
  &:before {
    height: 0;
    width: 0;
    top: 0;
  }
}

.bulk-dropdown .dropdown-menu {
  padding: 0;
  transform: translate3d(0, 47.5px, 0) !important;
  min-width: 240px;
}

.bulk-dropdown .dropdown-item {
  padding: 0px 0 8px !important;
  margin: 0;
  text-align: center;

  p {
    // text-align: center;
  }

  &:hover {
    color: #1a73e8;
    background-color: transparent !important;
  }

  &.active {
    color: #1a73e8;
    background-color: transparent !important;
  }
}

.dropdown-content {
  margin: 0 auto;
}

.header-btn {
  height: 34px;
  line-height: 34px;
  border: 1px solid $theme-border-color;
  padding: 0 24px;
  border-radius: 20px;
  margin-top: 8px;
  background-color: $white-color;
  font-weight: 400;
}

.view_header{
  font-size: 14px;
  border: 1px solid #efefef;
  // width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.switch-dropdown {
  .dropdown-menu.show {
    inset: -54px 0px auto auto !important;
    min-width: 238px;
    border-radius: 2px;
    border: 0;
    box-shadow: 0px 3px 6px #00000029;
  }

  .close-svg {
    img {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 3px;

      &:hover {
        padding: 3px;
      }
    }
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 0 !important;
      color: $theme-font-heading-color;
      &:hover {
        color: $theme-font-heading-color;
      }
      
      .switch-dropdown-item {
        padding: 9px 10px 9px 14px;
  
      }
    }
  }
}


.user-dropdown {
  .dropdown-menu.show {
    inset: -54px -5px auto auto !important;
    min-width: 298px;
    max-width: 298px;
    border-radius: 0px;
    border: 0;
    box-shadow: 0px 3px 6px #00000029;
  }

  .dropdown-menu {
    border-radius: 0;

    .dropdown-header {
      padding: 19px 24px 16px;
      h4 {
        font-size: $font-size-20;
        line-height: $line-height-20;
        font-weight: $medium-font-weight;
      }
    }

    .user-dropdown-body {

    }

    .dropdown-item {
      text-align: left;

      &:hover {
        color: #5f6368;
      }
      
      &.pb-0 {
        padding-bottom: 0 !important;
      }

      .header__dropdown {
        // .dropdown-content {
        //   height: 50px;
        //   width: 50px;
        //   margin: 0 15px;

        //   .initial-holder {
        //     font-size: 30px;
        //   }
        // }
      }

      p {
        margin: 0 15px 10px;
      }

      .header-btn {
        margin: 0 15px;
      }

      .user-holder-button {
        // padding: 15px;
        padding: 24.5px 24px 26px;
        border-top: 1px solid $theme-border-color;
        .ascent-button--header-buttons {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.user-dropdown {
  .dropdown-menu {
    .user-dropdown-body {
      .profile-icon-circle {
        padding-bottom: 0 !important;

        .profile-circle {
          &.dropdown-content {
            height: 60px;
            width: 60px;
            margin: 0 24px 8px;
  
            .profile-initial {
              font-size: 33px;
              line-height: 58px;
            }
          }
        }
      }

      .user-dd-details-wrapper {
        padding: 0px 0 16.5px !important;
        p {
          margin: 0 24px 4px;

          &.user-name {
            font-size: $font-size-16;
            line-height: $line-height-18;
            color: $theme-font-heading-color;
            font-weight: $medium-font-weight;
          }

          &.user-email {
            font-size: $font-size-12;
            line-height: $line-height-18;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}