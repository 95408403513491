@import "@scss/common/variables";

.user-details-wrapper {
  .user-details-wrapper__add-user-title {
    margin-left: 8px;
  }
}

.user-details-wrapper-add {
  float: right;
  margin-right: 36px;
}
.rolls-wrapper {
  max-width: 752px;
  width: 100%;
  margin: 24.5px auto 0;
  .rolls-wrapper__form-group input.rolls-form-control {
    max-width: 364px;
    width: 100%;
  }
  .rolls-wrapper__form-group label.rolls-label {
    font-size: 14px;
    color: $theme-font-primary-color;
    font-weight: 500;
  }
  .rolls-wrapper__permission {
    margin-bottom: 24px;
    .rolls-wrapper__permission-title {
      font-size: 20px;
      color: $theme-font-heading-color;
      margin-bottom: 5px;
      font-weight: 400;
    }
    .rolls-wrapper__permission-text {
      font-size: 12px;
      color: $theme-font-primary-color;
    }
  }
}
.rolls-details-wrapper__title {
  text-transform: capitalize;
  font-size: 14px;
  color: $theme-font-heading-color;
  font-weight: 500;
  padding-right: 8px;
  background-color: $white-color;
  z-index: 5;
}
.rolls-details-wrapper__form-group {
  background-color: $white-color;
  z-index: 2;
  input.rolls-details-form-control {
    margin: 0 8px;
  }
}
.rolls-details-list__form-group label.rolls-detail__select-all,
.rolls-details-list__form-group label.rolls-detail__select {
  color: $theme-font-heading-color;
  font-size: 14px;
  font-weight: 400;
}
.rolls-details-wrapper {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    width: 100%;
    height: 2px;
    background-color: #dadada;
  }
}

.add-rolls-main-wrapper {
  .rolls-wrapper {
    .rolls-wrapper__form {
      .rolls-label {
        font-size: $font-size-14;
        line-height: $line-height-18;
        font-weight: $medium-font-weight;
        color: $theme-font-primary-color;
        margin-left: 0;
        margin-bottom: 8px;
        &.rolls-label-error {
          color: $validation-font-color;
        }
      }

      .rolls-form-control {
        height: 44px;
        padding: 13px 16px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        color: $theme-font-heading-color;
      }
    }

    .rolls-wrapper__form-group {
      .rolls-label {
        font-size: $font-size-14;
        line-height: $line-height-18;
        font-weight: $medium-font-weight;
        color: $theme-font-primary-color;
        margin-left: 0;
        margin-bottom: 8px;
        &.rolls-label-error {
          color: $validation-font-color;
        }
      }

      textarea {
        padding: 13px 16px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        color: $theme-font-heading-color;
      }
    }
  }
}

.role_details_tabHeader_wrap {
  .inner-page-navigation-with-back-cta-buttons {
    padding: 0;
    > .container-fluid {
      padding: 0;
    }
  }
}


.add-rolls-main-wrapper {
  .custom-checkbox--bg-blue {
    border-width: 2px;
    transition: all .3s ease-in-out;
    // position: relative;

    img {
      &.uploaded-tick {
        display: none;
      }
    }

    &:before {
      content: "";
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: none;
      height: 12px;
      left: 4px;
      position: absolute;
      top: -1px;
      transform: rotate(45deg);
      width: 6px;
    }
  }

  .default-checkbox:checked ~ .custom-checkbox--bg-blue {
    border-color: #1a73e8;
  }

  .default-checkbox:checked {
    ~ .custom-checkbox--bg-blue {
      img {
        &.uploaded-tick {
          display: none;
        }
      }

      &:before {
        display: block;
      }
    }
  }

  .custom-input-checkbox-wrap {
    width: 18px;
    height: 18px;
  }

  .roles-module-name-header {
    font-size: $font-size-20;
    line-height: $line-height-25;
    font-weight: $medium-font-weight;
    color: $theme-font-heading-color;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .roles-checkbox-module-permission-wrap {
    margin-bottom: 16px;
  }

  .permission-checkbox-module-name {
    font-size: $font-size-14;
    line-height: $line-height-18;
    font-weight: $medium-font-weight;
    margin-left: 10px;
  }

  .permission-checkbox-permission-name {
    margin-left: 8px;
    text-transform: capitalize;
  }

  .mw-90 {
    min-width: 90px;
  }
}