@import "@scss/common/variables";
.table-uploaded-details-wrapper {
  margin: 0 25px;

  .temaplte-table-container {
    // display: block;
    display: contents;
    position: relative;
    table {
      max-width: 100%;
      // margin: 20px 49px;
      overflow-x: auto;
      display: block;
      thead {
        border-bottom: 1px solid #dadce0;
        th {
          padding-bottom: 16px;
          align-items: flex-start;
          .header {
            color: #02052b;
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 3px;
          }
          .subHeader {
            color: #5f6368;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      tr {
        height: 70px;
        border-bottom: 1px solid #dadce0;
        td {
          justify-content: center;
          padding: 10px 0;
          .body-container {
            // max-width: 340px;
            min-width: 250px;
            width: 100%;
            margin-right: 40px;
            .title {
              color: #5f6368;
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2;
              margin-bottom: 3px;
              text-align: left;
              width: 100%;
              word-break: break-word;
            }
            .title-green {
              color: green;
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2;
              margin-bottom: 3px;
              text-align: left;
              width: 100%;
              word-break: break-word;
            }

            .desc-text {
              color: #5f6368;
              font-size: 12px;
              font-weight: 400;
              min-width: 340px;
              width: 100%;
              text-align: left;
              word-break: break-word;
            }
          }
        }
      }
      tr:last-child td {
        width: 100%;
      }
    }
  }
}
.keys-container {
  flex-wrap: wrap;
  // margin-top: 20px;
}
.key-title-container {
  margin-right: 10px;
  border: 1px solid #efefef;
  margin-top: 5px;
  border-radius: 5px;
  min-width: 100px;
  cursor: pointer;
  &:hover {
    background-color: #e8f0fe;
  }
}
.key-header {
  font-size: 16px;
  padding: 10px;
  color: #1a73e8;
  font-weight: 500;
}
.key-title {
  font-size: 14px;
  padding: 10px;
  text-align: center;
}

.user-details-wrapper-modal {
	// margin-top: 65px;
	width: 66%;
	.user-details-wrapper__header {
		border-bottom: 1px solid $theme-border-color;
		padding: 0 24px 0;

		.user-details-wrapper__header-back-link {
			max-width: 24px;
			width: 100%;
		}
		.user-details-wrapper__add-user-info {
			text-align: center;
			color: var(--primary-color);
			position: relative;
            margin-left: 20px;

			&:after {
				content: "";
				position: absolute;
				bottom: -15px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--primary-color);
			}
		}
		.user-details-wrapper__cta-btn {
			height: 40px;
			line-height: 40px;
			margin: 4px 0;
			border-radius: 4px;
			border: none;
			background-color: #ffffff;

			&--primary {
				color: rgba(var(--button-primary), 1);
				background-color: #ffffff;
				margin-right: 8px;
				border: 1px solid $theme-border-color;
				padding: 0 40px;
			}
			&--secondary {
				background-color: rgba(var(--button-primary), 1);
				color: #ffffff;
				padding: 0 24px;
			}
		}
	}

	.user-details-wrapper__form-wrapper {
		max-width: 744px;
		width: 100%;
		margin: 20px auto;
		.user-details-wrapper__form-roles
			.form-group
			.user-details-wrapper__form-control {
			width: 100%;
		}
		.user-details-wrapper__form-batch-wrapper {
			width: 100%;
			border: 1px solid $theme-border-color;
			padding: 8px;
			border-radius: 4px;
			gap: 8px;
			button.badge {
				color: var(--primary-color);
				background-color: #fff5ea;
				border-radius: 18px;
				border: none;
				font-weight: 400;
				height: 34px;
				line-height: 34px;
				padding: 0 16px;
				font-size: 14px;
				img.close-btn {
					margin-left: 8px;
				}
			}
		}
	}
	.user-details-wrapper__form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;
		&--grid-gap-vendor-profile {
			gap: 0 24px;
		}
		&--org-profile {
			gap: 0 24px;
		}
	}
	.user-details-wrapper__form-group {
		min-width: 360px;
		width: 100%;
		margin-bottom: 0;
	}
}
.user-details-wrapper_right-modal {
	// margin-top: 65px;
	width: 33%;
	.user-details-wrapper__header {
		border-bottom: 1px solid $theme-border-color;
		padding: 0 24px 0;

		.user-details-wrapper__header-back-link {
			max-width: 24px;
			width: 100%;
		}
		.user-details-wrapper__add-user-info {
			text-align: center;
			color: var(--primary-color);
			position: relative;
            margin-left: 20px;

			&:after {
				content: "";
				position: absolute;
				bottom: -15px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--primary-color);
			}
		}

		.user-details-wrapper__cta-btn {
			height: 40px;
			line-height: 40px;
			margin: 4px 0;
			border-radius: 4px;
			border: none;
			background-color: #ffffff;

			&--primary {
				color: rgba(var(--button-primary), 1);
				background-color: #ffffff;
				margin-right: 8px;
				border: 1px solid $theme-border-color;
				padding: 0 40px;
			}
			&--secondary {
				background-color: rgba(var(--button-primary), 1);
				color: #ffffff;
				padding: 0 24px;
			}
		}
	}

	.user-details-wrapper__form-wrapper {
		max-width: 744px;
		width: 100%;
		margin: 20px auto;
		.user-details-wrapper__form-roles
			.form-group
			.user-details-wrapper__form-control {
			width: 100%;
		}
		.user-details-wrapper__form-batch-wrapper {
			width: 100%;
			border: 1px solid $theme-border-color;
			padding: 8px;
			border-radius: 4px;
			gap: 8px;
			button.badge {
				color: var(--primary-color);
				background-color: #fff5ea;
				border-radius: 18px;
				border: none;
				font-weight: 400;
				height: 34px;
				line-height: 34px;
				padding: 0 16px;
				font-size: 14px;
				img.close-btn {
					margin-left: 8px;
				}
			}
		}
	}
	.user-details-wrapper__form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;
		&--grid-gap-vendor-profile {
			gap: 0 24px;
		}
		&--org-profile {
			gap: 0 24px;
		}
	}
	.user-details-wrapper__form-group {
		min-width: 360px;
		width: 100%;
		margin-bottom: 0;
	}
}