@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.form_wrapper-pipeline {
  width: 80%;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: 24px;
  margin: 0 auto;
  margin-top: 5%;
  margin-bottom: 5%;
  align-items: center;
  .form-control {
    // min-width: 380px !important;
  }
}

.form_wrapper-pipeline_custom {
  width: 80%;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: 24px;
  margin: 0 auto;
  // margin-top: 5%;
  margin-bottom: 2%;
  align-items: center;
  .form-control {
    width: 380px !important;
  }
}