@import "@scss/common/variables";

//admin-user-wrapper

.admin-wrapper {
  width: 100%;

  .admin-wrapper__add-search {
    background-color: #ff9933;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    svg {
      margin: auto;
    }
  }
  .admin-wrapper__search {
    padding: 8px 17px;
    .admin-wrapper__search-title {
      font-size: 10px;
      color: $theme-font-primary-color;
      margin-top: 5px;
    }
  }
}


.more-dropdown {
  margin-right: 20px;
}
.more-dropdown .dropdown-header {
  display: flex;
  justify-content: space-between;
}
.more-dropdown .dropdown-menu.show {
  margin-top: -15px !important;
}
.more-dropdown .dropdown .btn {
  height: 34px;
}
.more-dropdown .btn-secondary {
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary.dropdown-toggle {
  border: none !important;
}
.more-dropdown .btn {
  padding: 0 0px;
  border: none;
}
.more-dropdown .dropdown {
  &:before {
    height: 0;
    width: 0;
    top: 0;
  }
}
.more-dropdown .dropdown-menu {
  padding: 0;
  transform: translate3d(0, 47.5px, 0) !important;
  min-width: 200px;
}
.more-dropdown .dropdown-item {
  padding: 0px 0 4px !important;
  margin: 0;
  text-align: center;
  //   margin-left: 10px;

  p {
    text-align: center;
    margin-left: 10px;
  }
}

.more-dropdown .more-dropdown-item {
  display: flex;
  padding: 7px;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  width: 100%;
  p {
    font-size: 14px;
  }

  &.active {
    color: #d7242e;
    background-color: #f7f7f7 !important;
    border-left: 2px solid #d7242e;
  }
}

.action-icon-td {
  overflow: visible;

  .table-action-icon-wrap {
    width: 30px;
    padding: 0 8px;
    .dropdown-menu {
      margin-top: 35px;
      border-radius: 2px;
      border: 0;
      box-shadow: 4px 4px 10px #00000029;
      padding: 16px 0 ;

      .dropdown-item {
        padding: 10px 16px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        color: $theme-font-heading-color;

        &:hover {
          background-color: #F7F7F7;
        }

        a {
          color: $theme-font-heading-color;

          &:hover {
            color: $theme-font-heading-color;
          }
        }

        p {
          font-size: $font-size-14 !important;
          line-height: $line-height-18 !important;
          color: $theme-font-heading-color;
        }
      }
    }
  }
}