.image_container_document {
  margin-right: 10px;
  .image_container_body {
    height: 140px !important;
    width: 206px;
    border: 1px solid #dadce0 !important;
    border-bottom: none !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden !important;
    .static_image {
      width: 65px;
    }
    .static_image_main {
      width: 45px;
    }
    .custom_image {
      height: 100%;
      width: 100%;
    }
    background-color: white;
  }
  .image_containertext_body {
    height: 52px;
    width: 206px;
    border-top: none !important;
    border: 1px solid #dadce0 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1000;
    overflow: hidden !important;
    background-color: white;
  }
}
