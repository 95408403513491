@import '@scss/common/variables';
.common-sticky-subheader {
	position: fixed;
	top: 65px;
	left: 0;
	right: 0;
	background-color: $white-color;
	z-index: 5;
}
.inner-page-navigation-with-back-cta-button__header-title {
	font-size: 16px;
	font-weight: normal;
}

.inner-page-navigation-with-back-cta-button__header {
	margin-left: 32px;
}

.inner-page-navigation-with-back-cta-buttons__navigation_left {
	left: 20%;
	transform: translateX(-50%);
	bottom: 0;
}

.inner-page-navigation-with-back-cta-buttons {
	border-bottom: 1px solid #dadce0;
	// margin-top: 6px;
	margin-top: 0px;
	// padding: 0 12px;
	padding: 0 14.75px;


	.ascent-button--header-buttons {
		margin: 5px 0 5px 16px;
		padding: 0 10px;
	}
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item-link {
	font-size: 14px;
	color: $theme-font-primary-color;
	display: inline-block;
	padding-bottom: 16px;
	position: relative;
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item-link:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
.inner-page-navigation-with-back-cta-buttons__nav-list-item.active {
	color: rgba(var(--primary-color), 1);
	padding-bottom: 16px;
	border-bottom: 2px solid rgba(var(--primary-color), 1);
}
.inner-page-navigation-with-back-cta-buttons__nav-list-item.active:after {
	background-color: rgba(var(--primary-color), 1);
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item {
	margin-right: 32px;
	&:last-child {
		margin: 0;
	}
}

.history-header-button {
	// min-width: 115px;
    height: 40px;
    padding: 10px 16px;

	.header_text__16 {
		line-height: 20px;
		margin-left: 8px;
		color: $theme-font-primary-color;
	}
}