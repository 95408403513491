@import "@scss/common/variables";
.modules_fields_container {
  overflow: auto;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  .modules_fields_table {
    width: 60%;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    .modules_fields_thead {
      height: 70px;
      .modules_fields_th {
        padding: 1.4rem 1rem;
        width: 150px;
        border: 1px solid #ececf9;
        font-size: 14px;
      }
      .modules_fields_th_checkbox {
        text-align: center;
        width: 150px;
        border: 1px solid #ececf9;
        font-size: 14px;
      }
    }
    .modules_fields_tbody {
      .modules_fields_tr {
        .modules_fields_td {
          padding: 1.4rem 1rem;
          border: 1px solid #ececf9;
          font-size: 14px;
          color: black;
        }
        .modules_fields_td_checkbox {
          text-align: center;
          width: 150px;
          border: 1px solid #ececf9;
          font-size: 14px;
        }
        .modules_fields_td_settings {
          cursor: pointer;
          height: 30px;
          width: 30px;
          margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .image {
            height: 22px;
            width: 22px;
          }
          &:hover {
            background-color: #e8f0fe !important;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.modules_fields_container_plociy {
  // overflow: auto;
  // max-height: calc(100vh - 70px - 100px);
  .modules_fields_table {
    width: 60%;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    .modules_fields_thead {
      height: 70px;
      .modules_fields_th {
        padding: 1.4rem 1rem;
        width: 150px;
        border: 1px solid #ececf9;
        font-size: 14px;
      }
      .modules_fields_th_checkbox {
        text-align: center;
        width: 150px;
        border: 1px solid #ececf9;
        font-size: 14px;
      }
    }
    .modules_fields_tbody {
      .modules_fields_tr {
        .modules_fields_td {
          padding: 1.4rem 1rem;
          border: 1px solid #ececf9;
          font-size: 14px;
          color: black;
        }
        .modules_fields_td_checkbox {
          text-align: center;
          width: 150px;
          border: 1px solid #ececf9;
          font-size: 14px;
        }
        .modules_fields_td_settings {
          cursor: pointer;
          height: 30px;
          width: 30px;
          margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .image {
            height: 22px;
            width: 22px;
          }
          &:hover {
            background-color: #e8f0fe !important;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.custom_field_button_container {
  // position: absolute;
  display: flex;
  cursor: pointer;
  right: 50px;
  align-items: center;
  .custom_field_button_container_image {
    height: 22px;
    width: 22px;
  }
  .custom_field_button_container_text {
    color: #408dfb;
    font-size: 16px;
    padding-left: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.modulefields_detail_container {
  padding: 0;
  margin: 0 30px;

  .modulefields_detail_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 65px;
    padding: 10px 0;
    border-bottom: 1px solid #dedff3;

    .modulefields_detail_header_text {
      color: #21263c;
      // font-size: 24px;
      font-size: 20px;
    }
    .modulefields_detail_close {
      border: 1px solid #dedff3;
      padding: 5px;
    }
  }
  .modulefields_form_container {
    margin-top: 30px;
  }
}
.bulk-dropdown_field {
  margin-top: 40px;
  margin-left: 20px;
  min-height: 10vh;

  .bulk-dropdown_item {
    // margin-top: 5px;
    // padding-top: 10px;
  }
}

.drag_common_card{
  width: 60%;
  height: 70px;
  border-radius: 5px;
  border: 1px solid #dadce0;
  padding: 15px 18px;
}
.drag_field_name{
  width: 200px;
}
.drag_seq_text{
  width: 40px;
}

.trips-add-feilds-wrapper {
  .modulefields_detail_header_container {
    height: auto;
    padding: 10px 0;
    font-weight: $medium-font-weight;
    
    .modulefields_detail_header_text {
      font-size: 20px;
    }
  }
  .user-details-wrapper__form-group {
    > label, .checkpoint-active-details__label {
      font-size: $font-size-14;
      line-height: $line-height-18;
      font-weight: $medium-font-weight;
      margin-bottom: 8px;
      margin-left: 0;

      &.form-label-error {
        color: $validation-font-color !important;
      }
    }
  }
}

.modulefields_detail_container {
  .navbar_policy  {
    &.nav-tabs {
      padding: 16px 0 0 0px;

      .navlink_policy  {
        text-align: center;

        p {
          margin-top: 5px;
        }

        &:hover {
          color: $theme-primary-color;
          border-color: transparent;
        }

        &.active {
          color: $theme-primary-color !important;
        }
      }
    }
  }

  .checkpoint-active-details-wrapper__form-wrapper {
    .checkpoint-active-details-wrapper__form-content_new {
      .checkpoint-active-details__label {
        font-size: $font-size-14;
        line-height: $line-height-18;
        font-weight: $medium-font-weight;
        margin-bottom: 8px;
        margin-left: 0;

        &.form-label-error {
          color: $validation-font-color !important;
        }
      }

      .user-details-wrapper__form-group {
        > label, .checkpoint-active-details__label {
          font-size: $font-size-14;
          line-height: $line-height-18;
          font-weight: $medium-font-weight;
          margin-bottom: 8px;
          margin-left: 0;
  
          &.form-label-error {
            color: $validation-font-color !important;
          }
        }
  
        .dropdownselect-form {
          .selected-value-box {
            height: 44px;
            padding: 13px 16px;
  
            .search_input {
              font-size: $font-size-14;
              line-height: $line-height-18;
              color: $theme-font-heading-color;
            }
            
            .right_container {
              top: 7px;
              right: 8px;
            }
          }
        }
  
        textarea {
          padding: 13px 16px;
          font-size: $font-size-14;
          line-height: $line-height-18;
          color: $theme-font-heading-color;
        }

        input {
          &.form-control {
            padding: 13px 16px;
            font-size: $font-size-14;
            line-height: $line-height-18;
            color: $theme-font-heading-color !important;
            height: 44px;
          }
        }
      }

      .enable-expense-level-fields-label-wrap {
        .css-b62m3t-container {
          .css-13cymwt-control, .css-t3ipsp-control {
            min-height: 70px;
            align-items: start;
            padding: 13px 16px;
          }
          .css-1fdsijx-ValueContainer, .css-3w2yfm-ValueContainer  {
            margin-left: 0;
            padding-left: 0;
            padding-top: 0;
          }
        }
      }
    }
  }

  .form-error {
    margin-top: 0;

    .srv-validation-message {
      margin-top: 4px;
    }
  }
}

.expense-active-category-add-form-wrap {
  padding: 24px;

  .user-details-wrapper__form-group {
    > label, .checkpoint-active-details__label {
      font-size: $font-size-14;
      line-height: $line-height-18;
      font-weight: $medium-font-weight;
      margin-bottom: 8px;
      margin-left: 0;

      &.form-label-error {
        color: $validation-font-color !important;
      }
    }

    .dropdownselect-form {
      .selected-value-box {
        height: 44px;
        padding: 13px 16px;

        .search_input {
          font-size: $font-size-14;
          line-height: $line-height-18;
          color: $theme-font-heading-color;
        }
        
        .right_container {
          top: 7px;
          right: 8px;
        }
      }
    }

    textarea {
      padding: 13px 16px;
      font-size: $font-size-14;
      line-height: $line-height-18;
      color: $theme-font-heading-color;
    }

    input {
      &.form-control {
        padding: 13px 16px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        color: $theme-font-heading-color !important;
        height: 44px;
      }
    }

    .css-b62m3t-container {
      .css-13cymwt-control, .css-t3ipsp-control {
        min-height: 44px;
        align-items: center;
        padding: 0px 2px 0 16px;
      }
      .css-1fdsijx-ValueContainer, .css-3w2yfm-ValueContainer  {
        margin-left: 0;
        padding-left: 0;
        padding-top: 0;
      }
    }
  }
}