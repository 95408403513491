@import "@scss/common/variables";

.policies_mainbody{
    overflow: auto;
    // max-height: calc(100vh - 178px) !important;
    // height: calc(100vh - 178px) !important;
    max-height: calc(100vh - 192px) !important;
    height: calc(100vh - 192px) !important;
}
.mileage_type_container{
    width: 100px;
    border: 1px solid #dadce0;
    border-radius: 5px;
}
.vehicle_container{
    width: 70%;
    border: 1px solid #dadce0;
    border-radius: 5px;  
    padding: 20px;
}
.vehicle_add_container{
    padding: 5px;
    border: 1px solid #e8f1ff;
    border-radius: 5px;
    background-color: #e8f1ff;
}
.rate_add_container{
    padding: 5px;
    border: 1px solid #408dfb;
    border-radius: 5px;
    background-color: #408dfb;  
    // width: 120px;
}
.mileage_rate_container{
    width: 70%;
    border: 1px solid #dadce0;
    border-radius: 5px;  
    padding: 20px;
    background-color: #f8f8fd;
    min-height: 30vh;
}
.w{
    &-20{
        width: 30%;
    }
}

.policy-details-wrapper {
    

    .policy-tabs-wrapper {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        margin: 0 auto 20px;
        

        .navbar_policy  {
            border: 0;
            justify-content: center;

            .navitem_policy  {
                &:last-child {
                    .navlink_policy {
                        &:after {
                            display: none;
                        }
                    }
                }

                &:hover {
                    svg {
                        fill:#D7242E ;

                        path {
                            fill:#D7242E ;

                            &#Path_46860, &#Path_46862 {
                                fill: none;
                            }
                        }
                    }
                    
                    p {
                        color: #D7242E;
                    }
                }
                .navlink_policy  {
                    border: 0;
                    text-align: center;
                    position: relative;

                    &:after {
                        content: "";
                        display: block;
                        width: calc(100% - 17.5px - 10px);
                        height: 1px;
                        background-color: $theme-border-color;
                        position: absolute;
                        top: 20px;
                        left: calc(50% + 8.5px + 5px);
                        z-index: 9;

                    }

                    &.active {
                        border: 0 !important;
                        svg {
                            fill:#D7242E ;

                            path {
                                fill:#D7242E ;

                                &#Path_46860, &#Path_46862 {
                                    fill: none;
                                }
                            }
                        }
                        
                        p {
                            color: #D7242E;
                        }
                    }

                    &:hover {
                        color: #D7242E;
                        p {
                            color: #D7242E;
                        }
                    }

                    .icon-holder {
                        margin-bottom: 8px;
                    }

                    p {
                        font-size: $font-size-14;
                        line-height: $line-height-18;
                        color: $theme-font-primary-color;
                        font-weight: $medium-font-weight;
                    }
                }
            }
        }
    }

    .policies_mainbody {
        .policy-setting-tabs-content-wrap {
            margin: 30px auto 0;

            .policy-setting-header-text {
                h6 {
                    color: $theme-font-heading-color;
                    font-size: $font-size-20;
                    line-height: $line-height-25;
                    font-weight: $medium-font-weight;
                    margin-bottom: 1px;
                }

                .sub-text {
                    margin-bottom: 16px;
                    font-size: $font-size-12;
                    line-height: $line-height-18;
                    color: $theme-font-primary-color;
                }
            }

            .user-details-wrapper__form-group {
                label {
                    font-size: $font-size-14;
                    line-height: $line-height-18;
                    font-weight: $medium-font-weight;
                    margin-bottom: 8px;
                    margin-left: 0;

                    &.form-label-error {
                        color: $validation-font-color !important;
                    }
                }

                input {
                    &.form-control {
                        height: 44px;
                        padding: 13px 16px;
                        font-size: $font-size-14;
                        line-height: $line-height-18;
                        color: $theme-font-primary-color !important;
                    }
                }

                textarea {
                    padding: 13px 16px;
                    font-size: $font-size-14;
                    line-height: $line-height-18;
                    color: $theme-font-primary-color !important;
                }
            }
        }

        .expense-limits-tabs-content-wrap {
            margin: 30px 30px 0;

            .expense-limits-header-text {
                h6 {
                    color: $theme-font-heading-color;
                    font-size: $font-size-20;
                    line-height: $line-height-25;
                    font-weight: $medium-font-weight;
                    margin-bottom: 1px;
                }

                .sub-text {
                    margin-bottom: 16px;
                    font-size: $font-size-12;
                    line-height: $line-height-18;
                    color: $theme-font-primary-color;
                }
            }

            .modules_fields_container_plociy {
                .modules_fields_table {
                    width: auto;
                    margin: 0 0 24px 0;

                    .modules_fields_thead {
                        height: auto;

                        .modules_fields_th {
                            padding: 13px 16px;

                            &::after {
                                display: none;
                            }
                        }
                    }

                    .modules_fields_tbody {
                        .modules_fields_tr {
                            td {
                                padding: 13px 16px !important; 
                            }
                        }
                    }
                }
            }
        }

        .mileage-limits-tabs-content-wrap {
            margin: 30px auto 0;
            width: 70%;

            .vehicle_container, .mileage_rate_container {
                width: 100%;
            }

            .label-holder {
                font-size: $font-size-14;
                line-height: $line-height-18;
                font-weight: $medium-font-weight;
                margin-bottom: 8px;
                margin-left: 0;

                &.form-label-error {
                    color: $validation-font-color !important;
                }
            }

            .mileage-expense-label-wrap {
                .form-label {
                    font-size: $font-size-14;
                    line-height: $line-height-18;
                    font-weight: $medium-font-weight;
                    margin-bottom: 8px;
                    margin-left: 0;

                    &.form-label-error {
                        color: $validation-font-color !important;
                    }
                }

                .selected-value-box {
                    height: 44px;
                    padding: 13px 16px;

                    .right_container {
                        right: 8px;
                        top: 7px;
                    }
                }
            }
        }
    }
}

.general-limits-form-holder {
    .desc_text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        margin-bottom: 8px;
        margin-left: 0;
        margin-top: 0;
    }

    .checkpoint-active-details__label {
        font-size: 14px !important;
        line-height: 18px;
        font-weight: 500 !important;
        margin-bottom: 8px;
        margin-left: 0;
        margin-top: 0;

        &.form-error-label {
            color: $validation-font-color !important;
        }
    }

    input {
        &.form-control {
            height: 44px;
            padding: 13px 16px;
            font-size: $font-size-14;
            line-height: $line-height-18;
            color: $theme-font-primary-color;
        }
    }

    .selected-value-box {
        height: 44px;
        padding: 13px 16px;

        .right_container {
            top: 7px;
            right: 8px;
        }
    }
}

.user-details-wrapper__form--org-profile {
    &.three-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.ascent-button--secondary {
&.btn-preference-submit {
    padding: 5px 10px;
    &:disabled {
        background-color: #e9eaec;
    }
    &:hover {
        background-color: rgba(var(--button-primary), 1);
        color: #ffffff;
    }
}   
}

.preferenc-card-wrap {
    .card {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.enable-trip-limit-wrap {
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}


//Per Diem CSS
.per-diem-tabs-content-wrap {
    margin: 30px auto 0;
    width: 70%;

    .create-per-diem-travel-hours {
        padding: 10px;
        border: 1px solid $theme-border-color;
        border-radius: 4px;

        .rate-for-location-dropdown {
            &::before {
                display: none;
            }

            
            .btn {
                padding: 7px 35px 7px 7px;
                position: relative;
            }

            .dropdown-toggle::after {
                display: inline-block;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .5em solid;
                border-right: .5em solid transparent;
                border-bottom: 0;
                border-left: .5em solid transparent;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            .dropdown-menu {
                &.show {
                    inset: 43px auto auto 0px !important;
                }
            }
        }
    }


}

.per-diem-rate-hours-holder {
    .react-datepicker__input-container input.form-control {
        border: 1px solid $theme-border-color !important;
    }
}

.add-rate-location-button {
    padding: 5px;
    position: relative;

    &::before {
        display: none;
    }

    .arrow_image {
        width: 24px;
    }
}


.rate-for-location-dropdown {
    &::before {
        display: none;
    }

    
    .btn {
        padding: 7px 35px 7px 7px;
        position: relative;
        font-size: 14px;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .4em solid;
        border-right: .4em solid transparent;
        border-bottom: 0;
        border-left: .4em solid transparent;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .dropdown-menu {
        &.show {
            inset: 43px auto auto 0px !important;
        }
    }
}

.currency-rate-input-wrap {
    .css-13cymwt-control, .css-t3ipsp-control {
        min-height: 44px;
        box-shadow: none !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: $theme-border-color;
         &:hover {
            
         }
    }

    .cr-input-wrap {
        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.per-diem-rate-list {
    .datatable_table_td:nth-child(1) {
        text-align: left !important;
    }
}

.per-diem-popup-wrap {
    .css-13cymwt-control, .css-t3ipsp-control {
        min-height: 44px;
    }
}