/* For WebKit Browsers */
::-webkit-scrollbar {
    width: 7px; /* Width of the scrollbar */
    height: 6px; /* Horizontal scrollbar height */
  }
  
  ::-webkit-scrollbar-track {
    background: #f2f4fb; /* Background color of the track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c7cfef; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners */
  }
  
  /* For Firefox */
  /* Note that customizing scrollbars in Firefox requires a different approach using overlay scrollbars */
  /* This example changes the color of the scrollbar thumb */
  /* Keep in mind that Firefox's scrollbar customization is limited */
  html {
    scrollbar-color: #d9def0 #f2f4fb;
  }
  
  /* For Microsoft Edge */
  /* Customizing scrollbars in Edge uses a different pseudo-element */
  /* This example changes the color of the scrollbar thumb */
  /* Edge's scrollbar customization is also limited */
  ::-ms-scrollbar-thumb {
    background: #d9def0;
  }