@import "@scss/common/variables";

.personal-information_container {
  overflow: auto;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);

  .personalInfo__main_container {
    .header {
      background-color: #f4f4fb;
      height: 40px;
      border-bottom: 1px solid #dadce0;
    }
    border: 1px solid #dadce0;
    width: 70%;
    min-height: 50vh;
    border-radius: 5px;
    overflow: hidden;
    .personalInfo__container {
      display: flex;
      flex-direction: row; /* Columns will be placed horizontally */
      justify-content: space-between; /* Space between the columns */
      .column {
        width: 50%; /* Adjust the width as needed */
        padding: 10px;
        border-right: 1px solid #efefef;
      }
    }
  }
}

.basic_information_grid {
  flex-wrap: wrap;
  display: flex;
}

.personal-information_container {
  .personalInfo__main_container {
    margin: 24.5px auto 24.5px 77.5px;
    width: auto;
    max-width: 984px;
    @media only screen and (max-width: 1250px) {
      width: auto;
      max-width: 100%;
      margin-right: 77.5px;
    }
    
    .personalInfo__body_container {
      margin: 24px 48.5px 24.5px;

      .header {
        height: auto;
        background-color: transparent;
        text-align: center;
        padding-bottom: 16.5px;
        margin-bottom: 24.5px;
        color: $theme-font-heading-color;
        
        p {
          font-size: $font-size-20;
          line-height: $line-height-25;
          color: $theme-font-heading-color;
          font-weight: $medium-font-weight;
        }
      }
      .personalInfo__container {
        .column {
          padding: 0 48px;
          border-color: $theme-border-color;

          &:first-child {
            padding-left: 46px;
          }

          &:last-child {
            padding-right: 47px;
            border-right: 0;
          }

          .personalInfo__container_labelfields_wrap {
            border-bottom: 1px solid $theme-border-color;
            margin-bottom: 16.75px;
            padding-bottom: 16.75px;

            &:last-child {
              border: 0;
              padding-bottom: 0;
              margin-bottom: 0;
            }

            .personalInfo__container_label {
              font-size: $font-size-14;
              line-height: $line-height-18;
              font-weight: $medium-font-weight;
              color: $theme-font-primary-color;
              margin-bottom: 8px;
            }

            .personalInfo__container_value {
              font-size: $font-size-16;
              line-height: $line-height-18;
              color: $theme-font-heading-color;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}