@import "@scss/common/variables";
.user-details-wrapper-form {
  // margin-top: 65px;
  .user-details-wrapper__header {
    border-bottom: 1px solid $theme-border-color;
    padding: 0 24px 0;

    .user-details-wrapper__header-back-link {
      max-width: 24px;
      width: 100%;
    }
    .user-details-wrapper__add-user-info {
      text-align: center;
      color: var(--primary-color);
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--primary-color);
      }
    }

    .user-details-wrapper__cta-btn {
      height: 40px;
      line-height: 40px;
      margin: 4px 0;
      border-radius: 4px;
      border: none;
      background-color: #ffffff;

      &--primary {
        color: rgba(var(--button-primary), 1);
        background-color: #ffffff;
        margin-right: 8px;
        border: 1px solid $theme-border-color;
        padding: 0 40px;
      }
      &--secondary {
        background-color: rgba(var(--button-primary), 1);
        color: #ffffff;
        padding: 0 24px;
      }
    }
  }

  .user-details-wrapper__form-wrapper {
    max-width: 744px;
    width: 100%;
    margin: 20px auto;
    .user-details-wrapper__form-roles
      .form-group
      .user-details-wrapper__form-control {
      width: 100%;
    }
    .user-details-wrapper__form-batch-wrapper {
      width: 100%;
      border: 1px solid $theme-border-color;
      padding: 8px;
      border-radius: 4px;
      gap: 8px;
      button.badge {
        color: var(--primary-color);
        background-color: #fff5ea;
        border-radius: 18px;
        border: none;
        font-weight: 400;
        height: 34px;
        line-height: 34px;
        padding: 0 16px;
        font-size: 14px;
        img.close-btn {
          margin-left: 8px;
        }
      }
    }
  }
  .user-details-wrapper__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    &--grid-gap-vendor-profile {
      gap: 0 24px;
    }
    &--org-profile {
      gap: 0 24px;
    }
  }
  .user-details-wrapper__form-group {
    min-width: 360px;
    width: 100%;
    margin-bottom: 0;
  }
}

.user-details-wrapper__form-group input.jsx-4179805763 {
  height: 44px;
  border: 1px solid $theme-border-color;
  border-radius: 4px;
}
.user-details-wrapper__form-group input.form-control {
  // min-width: 358px;
  color: #333333;
}
.user-details-wrapper__form-group-new input.form-control {
  min-width: 316px;
}

.vendor-profile-switch.custom-switch-wrapper {
  position: absolute;
  top: 55%;
  right: 8px;
  transform: translateY(-50%);
}
.vendor-profile-switch.custom-switch-wrapper-blue {
  position: absolute;
  top: 55%;
  right: 8px;
  transform: translateY(-50%);
}

.form-group .color-picker-edit.color-picker-edit--org-profile {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 1;
}
.vendor-profile-switch--position.custom-switch-wrapper {
  top: 40%;
}
.vendor-profile-switch--position.custom-switch-wrapper-blue {
  top: 40%;
}
.msme-certificate {
  border: 1px solid $theme-border-color;
  padding: 8px 16px;
  border-radius: 4px;
  max-width: 364px;
  margin-bottom: 16px;
}
.upload-files {
  color: #276ef1;
  font-size: 16px;
  font-weight: $medium-font-weight;
}
.switch {
  display: flex;
  align-items: center;
  margin-top: 10px;
  p {
    margin-right: 100px;
  }
}

.user-details-overview {
  .form_trips_wrapper {
    margin-top: 24.5px;
    margin-bottom: 24.5px;

    .form_users_wrapper_container {

      .form-label, .form-label-error {
        font-size: $font-size-14;
        line-height: $line-height-18;
        font-weight: $medium-font-weight;
        margin-bottom: 8px;
        margin-left: 0;
      }

      .form-label {
        color: $theme-font-primary-color;
      }

      .form-label-error {
        color: $validation-font-color;
      }

      .error-text {
        margin-top: 0;
        .srv-validation-message {
          margin-top: 8px;
        }
      }

      .custom-form-control {
        margin-bottom: 16px;
        height: 44px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        padding: 13px 16px;
        color: $theme-font-heading-color;
      }

      .tag-dropdownselect-form {
        .tag-multidropdown-box {
          margin-bottom: 16px;
          padding: 10px 16px;
          min-height: 44px;
          height: auto;
          color: $theme-font-heading-color;
        }
      }

      .selected-value-box {
        margin-bottom: 16px;
        height: 44px;
        font-size: $font-size-14;
        line-height: $line-height-18;
        padding: 13px 16px;
        color: $theme-font-heading-color;
        
        .search_input {
          font-size: $font-size-14;
          line-height: $line-height-18;
        }

        .right_container {
          top: 9px;
          right: 8px;

          .arrow_image_container {
            .arrow_image {
              height: 24px;
              width: 24px;
            }
          }
        }
      }

      .react-datepicker-wrapper {
        .react-datepicker__input-container  {
          .custom-form-control-date {
            height: 44px;
            padding: 13px 16px;
            font-size: $font-size-14;
            line-height: $line-height-18;
            color: $theme-font-heading-color;
          }
        }
      }

    }
  }
}