.App {
  font-family: sans-serif;
}
.home {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.tag-multidropdown-box {
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #dadbdc;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.tag-search_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .tag_container {
    position: relative; /* Position relative for child absolute positioning */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #dadce0;
    border-radius: 16px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 6px;
    padding-right: 10px;
    border: 1px solid #efefef;
    overflow: hidden;
  }
  .tag-text {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    color: rgb(79, 79, 79);
  }
  .tag-item-close {
    position: absolute;
    right: 0px;
    opacity: 0;
    transition: opacity 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 100%;
    width: 20px;
  }
  .tag_container:hover .tag-item-close {
    opacity: 1;
  }

  .search_image {
    height: 18px;
    width: 18px;
    font-size: 16px;
  }
  .tag-search_input {
    outline: none;
    border: none;
    height: auto;
    font-size: 14px;
    margin-top: 5px;
    width: 100%;
    box-shadow: none;
    &::placeholder {
      color: #bec1c4;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.tag-search_input::placeholder {
  color: #dadce0;
  font-size: 14px;
}
.tag-multidropdown-box.active {
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #408dfb;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  // transition: all 200ms ease;
  width: 415px;

  height: auto;
  border-radius: 5px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  .value {
    text-align: left;
    position: absolute;
    top: -8px;
    color: black;
  }
  .placeholder {
    text-align: left;
    position: absolute;
    top: -8px;
    color: #dcdcdc;
  }
}

.tag-dropdownselect-form {
  position: relative;
  color: #333;
  cursor: default;
  // width: 300px;
  border-radius: 5px;
  height: auto;
}

.tag-dropdownselect-form .arrow.open {
  border-color: transparent transparent #dadce0;
  border-width: 0 5px 5px;
}

.tag-dropdownselect-form .tag-options {
  display: none;
  background-color: #fff;
  border: 1px solid #dadce0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: 10px;
  max-height: 275px;
  // overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 3;
  width: 415px;
  font-size: 14px;
  color: black;
}

.tag-dropdownselect-form .tag-options.open {
  display: block;
  margin-top: 5px;
  border-radius: 5px;
}

.tag-dropdownselect-form .tag_option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 5px 2px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.tag-dropdownselect-form .tag_option.selected {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected_tag_text {
  color: rgb(74, 74, 74);
  border: 1px solid #efefef;
  display: inline;
  border-radius: 14px;
  font-size: 13px;
}
.tag-dropdownselect-form .tag_option:hover {
  background-color: #f0f6fa;
  border-radius: 5px;
}
.loader {
  height: 40px;
}
.nodata {
  color: rgba(51, 51, 51, 0.8);
  font-weight: 400;
  text-align: center;
  padding: 10px;
}

.tag-option_container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  overflow-y: auto;
  max-height: 176px;
}
.mutlidropdown-tag-circle-container {
  flex-wrap: wrap;
  display: flex;
  .mutlidropdown-tag-circle {
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #dadce0;
  }
}
