.shimmer-effect-wrapper {
    .datatable_table  {
      .datatable_table_tbody {
        .datatable_table_tr {
          .datatable_table_td {
            height: 50px;
          }
        }
      }
    }
  }