
.more-dropdown-main {
    margin-right: 10px;
  }
  .more-dropdown-main .dropdown-header {
    display: flex;
    justify-content: space-between;
  }
  .more-dropdown-main .dropdown-menu.show {
    margin-top: -15px !important;
  }
  .more-dropdown-main .dropdown .btn {
    // height: 34px;
  }
  .more-dropdown-main .btn-secondary {
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-secondary.dropdown-toggle {
    border: none !important;
  }
  .more-dropdown-main .btn {
    padding: 0 0px;
    border: none;
  }
  .more-dropdown-main .dropdown {
    &:before {
      height: 0;
      width: 0;
      top: 0;
    }
  }
  .more-dropdown-main .dropdown-menu {
    padding: 0;
    transform: translate3d(0, 47.5px, 0) !important;
    min-width: 200px;
  }
  .more-dropdown-main .dropdown-item {
    padding: 0px 0 4px !important;
    margin: 0;
    text-align: center;
    //   margin-left: 10px;
  
    p {
      text-align: center;
      margin-left: 10px;
    }
  }
  .more-dropdown-main .more-dropdown-main-item {
    display: flex;
    padding: 7px;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    width: 100%;
    p {
      font-size: 14px;
    }
  
    &.active {
      color: #d7242e;
      background-color: #f7f7f7 !important;
      border-left: 2px solid #d7242e;
    }
  }
  