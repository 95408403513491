.itenary_main_section {
  overflow: auto;
  max-height: calc(100vh - 265px);
}
.flight_trip_itenary {
  min-height: 20vh;
}
.trip_info {
  right: 6px;
  top: 6px;
  background-color: #ebf0fe;
  padding: 5px;
  border-radius: 5px;
  p {
    color: #408dfb;
    font-size: 12px;
  }
}
